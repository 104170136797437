* {
  font-family: 'Poppins', sans-serif;
}

.permission-instructions{
  padding-top: 100px;
  display: none;
}
.permission-instructions p {
  color: red;
}
.permission-instructions button{
  padding: 5px;
  background: #499bce;
  border-radius: 5px;
  color: #fff;
}
.common_chat_area.inactive_camera{
  display: none;
}
.permission-instructions-audio{
  padding: 50px 3px 50px 3px;
}
.permission-instructions-audio p {
  color: red;
}
.permission-instructions-audio button{
  background: #54a0d0;
  color: white;
  padding: 5px;
  border-radius: 5px;
}
.inactive_audio{
  background: #ececec;
    text-align: center;
    padding: 50px;
}

/* tab css */
.nav-tabs a.active {
  pointer-events: none;
  cursor: default;
}

.nav-tabs a {
  color: #000;
}

.nav-tabs .nav-link.active {
  color: #499bce;
  background-color: #ececec;
  border-color: #ececec #ececec #fff;
}

.nav-tabs .nav-link:hover {
  border-color: #499bce #499bce #dee2e6;
}

.tab_vertical_img {
  filter: brightness(0) saturate(100%) invert(41%) sepia(82%) saturate(617%) hue-rotate(163deg) brightness(90%) contrast(94%);
  height: 35px;
  margin-right: 5px;
}

.nav-tabs {
  background: #c1c1c1;
}

/* End */
.vertical_tab_container {
  margin: 10px 20px;
}

.price_amount {
  font-size: 22px;
  font-weight: 800;
}

.desktop_profile {
  display: inline-block;
}

.swiper-container {
  overflow: hidden;
}

.swiper-slide img {
  cursor: pointer;
}

.mobile_profile {
  display: none;
}

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 0px !important;
  cursor: text;
  padding-bottom: 25px !important;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: 1px solid #aaa !important;
  outline: 0;
}

.wrapper input[type="date"] {
  background: none !important;
}

.text_more {
  display: inline-block;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no_data_found_css {
  border: 1px solid #d6d6d6;
  padding: 20px;
}

.text_more_product_chat_description {
  display: inline-block;
  width: 700px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.text_more_product_chat_list {
  display: inline-block;
  width: 170px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.eye_icon {
  position: absolute;
  top: 18px;
  right: 30px;
}

.password_area {
  position: relative;
}

.sidebar_close {
  display: none;
}

/* pagination */
.pagination {
  display: inline-block;
}

.pagination li {
  color: black;
  float: left;
  text-decoration: none;
}

.pagination li {
  padding: 5px;
  margin: 2px;
  margin-top: 10px;
  font-size: 12px;
  border: 1px solid #ddd;
  /* Gray */
}

.pagination li.active {
  background-color: #308ee0;
  color: white;
}

.pagination li:hover {
  background-color: #308ee0;
  color: white;
}

.pagination li a {
  color: #000;
}

.pagination li:hover:not(.active) {
  background-color: #ddd;
}

a {
  text-decoration: none;
}

.custom-disable {
  background-color: #f2f2f2;
  /* Custom background color for disabled dates */
  color: #999;
  /* Custom text color for disabled dates */
}

/* .datepicker table tr td.disabled, .datepicker table tr td.disabled:hover {
  background: none;
  color: #d70b0b !important;
  cursor: default;
} */
/* .datepicker table tr td.old.disabled {
  background: none;
  color: #41d414 !important;
  cursor: default;
} */
.datepicker table tr td.day.disabled {
  background: none;
  color: #b9b9b9 !important;
  cursor: default;
  /* border: 1px solid; */
}

.datepicker table tr td.booked {
  background: none;
  color: #499bce !important;
  font-weight: 500;
  cursor: default;
  cursor: not-allowed;
  pointer-events: none;
}

.wrapper input {
  cursor: pointer;
}

/* .datepicker table tr td.booked::before{
  content: '';
  display: inline-block;
  width: 15px;
  height: 15px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  background-color: #69b6d5;
} */
.custom-dropdown {
  display: flex;
}

.custom-dropdown .dropdown-icon {
  margin: 10px -20px;
  z-index: 90;
}

/* .form-control:focus, #gstn:focus {
  box-shadow: 0 0 0 1px rgb(0 88 255 / 47%) !important
} */

.welcome_area {
  margin: auto;
  width: 50%;
  padding: 90px 10px;
  text-align: center;
}

.each-chathistorybox.active {
  background-color: #cfe4fa;
  /* padding: 5px; */
}

.book_product_table_name {
  width: 40% !important;
}

.audio_cotainer {
  width: 200px;
  padding: 5px 13px;
}

.attachment {
  display: flex;
  justify-content: left;
  /* background-color: #1b82c5; */
  padding-left: 20px;
  gap: 0px;
}

.file-attachment {
  position: relative !important;
}

.file-attachment input {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.mobile-camera-icons input {
  position: absolute;
  opacity: 0;
  width: 30px;
  margin-top: 10px;
  margin-left: -30px;
}

.mobile_attachment_area .attachment {
  gap: 20px !important;
}

.msg-text {
  margin-bottom: 5px;
  position: relative;
}

.msg-text>a {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  right: auto;
}

.msg-text img {
  /*height: 70px;*/
  width: 300px;
}

.play-pouse i {
  color: red;
  font-size: 25px;
}

.attachment-header .retake,
.fa-solid,
.fa-paperclip,
.delete-record img,
.play-pouse i {
  cursor: pointer;
}

.inquery-detailed-image img,
.retake {
  cursor: pointer;
}

.left-msg .msg-bubble {
  background: #69afda;
}

.msg-text video {
  width: 100%;
}

.dashboare-book-area {
  max-width: 1100px !important;
}

.moreText {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.custom_promotion_img {
  border-radius: 5px;
  width: 100px;
}

.chat_container {
  height: 60vh;
  max-height: 60vh;
  overflow: auto;
  padding: 10px;
}

@media (max-width: 991px) {
  .product_image_area {
    width: 100% !important;
  }

  .mobile_profile {
    display: inline-block;
  }

  .desktop_profile {
    display: none;
  }

  .showImage,
  .showNoImage {
    width: 100% !important;
  }

  .msger-chat {
    width: 100%;
  }

  .mobile_popup_body {
    position: fixed !important;
    width: 100% !important;
    height: 100% !important;
    top: 0px !important;
    left: 0px !important;
    z-index: 99;
  }

  .show-attachment video {
    height: calc(100vh - 220px) !important;
  }

  .bottom-chat-bar>div:first-child {
    width: 111px;
    border-top: 1px solid #d6d6d6;
    background: #f6f6f6 !important;
  }

  .show-attachment video {
    height: calc(100vh - 220px) !important;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  .mobile-camera-icons>img {
    width: 30px;
    height: auto;
    position: relative;
    top: -20px;
  }

  .mobile-camera-icons>div {
    margin: 0px 50px !important;
    display: inline-block;
  }

  .attachment-header {
    margin-bottom: 0px !important;
  }

  .msger {
    bottom: 58px !important;
  }

  .dashboare-area>div.left-panel.active {
    max-height: 100vh !important;
    height: 100vh !important;
    padding-top: 7vh;
  }

  /* body.stop-scroll .header-icons-area {
    display: none;
  } */

  .main-menu-bar {
    top: 0px;
    width: 100%;
  }

  body.stop-scroll .main-menu-bar.active::before {
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0px;
    top: 0px;
    background: #000;
    opacity: .8;
    content: '';
  }

  .mobile_sidebar_close {
    color: #fff;
    text-align: right;
    font-size: 25px;
    padding: 10px;
    position: relative;
    z-index: 99;
  }

  .mobile_sidebar_close.common_sidebar {
    color: #000 !important;
  }

  .circle_close {
    height: 50px;
    width: 50px;
    border: 1px solid #bbb;
    border-radius: 50%;
    position: relative;
    display: inline-block;
  }

  .mobile_sidebar_close i {
    cursor: pointer;
    position: absolute;
    margin-left: -32px;
    margin-top: 10px;
  }

  .mobile-signup-dropdown {
    margin-top: 24px !important;
    padding: 0px !important;
    display: none;
  }

  .mobile-sign-up-area:hover .mobile-signup-dropdown {
    display: block;
  }

  .mobile-signup-dropdown ul {
    margin: 0px !important
  }

  .mobile-signup-dropdown li a {
    padding: 10px 15px;
    display: block;
    color: #fff;
    border-top: 1px solid #fff;
  }

  .mobile-signup-dropdown li:first-child a {
    border-top: 0px solid #fff;
  }
}

@media(min-width:992px) {
  .mobile_sidebar_close {
    display: none;
  }
}