/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
input[type=radio],
input[type=checkbox],
select {
  background-color: #fff !important;
  /* Default color for radio buttons */
  cursor: pointer;
}

/* src/global.scss */
@media (prefers-color-scheme: dark) {
  ion-input,
input,
select,
optgroup,
textarea {
    background-color: #ffffff !important;
    color: #000000 !important;
  }

  input[type=radio]:checked {
    accent-color: #0075ff;
    /* Color when checked */
  }

  input[type=radio]:hover {
    accent-color: #0075ff;
    /* Color when hovered */
  }

  input[type=checkbox]:checked {
    accent-color: #0075ff;
    /* Color when checked */
  }

  input[type=checkbox]:hover {
    accent-color: #0075ff;
    /* Color when hovered */
  }
}