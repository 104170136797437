@media(min-width:1300px){
	.container{
		max-width:1256px;
	}
	.product-listing-slider-area .container, .product-listing-wrapper .container{
		max-width:1300px;
	}
	.product-details .container{
		max-width:1300px;
	}
}
@media(max-width:1299px){
	.each-verticals {
		min-height: 255px;
	}
	.award-text-area {
		padding-left: 0px;
	}
	.dextop-menu > li > a {
		padding: 15px 16px;
	}
	.seller-registration {
		padding-left: 0px;
		padding-right: 0px;
	}
	.product-details-content {
		padding-left: 0px;
	}
	.button-area a {
		padding-left: 20px;
		padding-right: 20px;
	}

}
@media(max-width:1199px){
	.button-area {
		display:block !important;
	}
	.button-area a{
		width:100%;
	}
	.button-area a:first-child {
		margin-bottom: 20px;
	}
	.each-verticals {
		min-height: 255px;
	}
	.dextop-menu > li > a, .sign-up-area a {
		padding: 15px 7px;
		font-size: 15px;
	}
	.serch-area form input {
		font-size: 14px;
		padding: 5px 0px;
	}
	.serch-area form {
		max-width: 255px;
		padding: 0px 10px;
	}
	h2 {
		font-size: 42px;
	}
	.our-verticals-area h2 {
		margin-bottom: 25px;
	}
	.each-verticals {
		min-height: 210px;
	}
	.text-on-image h3 {
		font-size: 14px;
	}
	.what-is-engeconnect-area h2 img {
		height: 51px;
	}
	.each-verticals h3 {
		font-size: 14px;
	}
	.search-listing .row .col-lg-4{
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.notification-dropdown, .signup-dropdown{
		left: auto;
		transform: translateX(0%);
		right:-10px;
	}
	.dextop-menu > li > ul:before, .signup-dropdown:before, .notification-dropdown:before {
		left: auto;
		transform: translatex(0%);
		right:10px;
	}
}
@media(max-width:991px){
	.sidebar_close{
		display: block;
	}
	.serch-area, .sign-up-area{
		display:none;
	}
	.each-verticals {
		margin-bottom:30px;
		min-height: 330px;
	}
	.our-vertical-services {
		margin-bottom: 7px;
	}
	.each-counter-box {
		margin-bottom:30px;
	}
	.main-menu-bar {
		position:absolute;
		right:-100%;
		width:90%;
		min-height:100vh;
		z-index: 99;
		transition:all 0.5s;
		-webkit-transition:all 0.5s;
	}
	.main-menu-bar.active{
		right:0px;
		transition:all 0.5s;
		-webkit-transition:all 0.5s;
	}
	.dextop-menu > li {
		display: block;
	}
	.main-menu-bar .container{
		padding:0px;
	}
	.menu-bar{
		margin-right:0px;
	}
	.dextop-menu {
		text-align: right;
	}
	.dextop-menu > li > a{
		font-size: 17px;
		display:block;
		padding:0px;
		padding:15px 10vw;
		padding-right: 50px;
	}
	.menu-bar{
		padding-top:30px;
	}
	.dextop-menu > li > ul {
		position: relative;
		top: 0;
		min-width: auto;
		left: 0;
		transform: translateX(-0%);
		margin: 0px;
		padding: 0px;
		list-style: none;
		border-radius: 0px;
		padding: 0px 0px;
		box-shadow: none;
		margin-top: 0px;
		opacity: 1;
		pointer-events: all;
		background:#272829;
		padding-top:0px;
		padding-right:60px;
		max-height: 0px;
		overflow: hidden;
		transition:all 0.5s;
		-webkit-transition:all 0.5s;
	}
	.dextop-menu > li > ul:before{
		display:none;
	}
	.dextop-menu > li > ul > li > a {
		color: #dfdfdf;
		border-bottom: 0px solid #f3f3f3;
	}
	.has-submenu > a:after {
		margin-left: 10px;
		margin-right: -20px;
	}
	.has-submenu.active > ul{
		max-height:1000px;
		padding-top:15px;
		transition:all 0.5s;
		-webkit-transition:all 0.5s;
	}
	.has-submenu.active{
		background:#1a83c6;
	}
	.each-footer-info:not(:last-child){
		margin-bottom:50px;
	}
	.quick-links ul li {
		display: inline-block;
		width: 49%;
	}
	.footer-area {
		padding: 50px 0px;
	}
	.footer-area-wrapper > div {
		width: 100%;
	}
	.footer-area-wrapper {
		max-width: 395px;
		margin: 0px auto;
	}
	.featured-seller-products, .all-category-listing, .featured-product-wrapper {
		flex-wrap:wrap;
	}
	.featured-seller-products > div{
		width: calc(50% - 15px);
	}
	.each-category{
		width: calc(33.33% - 15px);
	}
	.seller-heading h2 {
		text-align:center;
	}
	.award-area{
		margin-bottom:30px;
	}
	.award-icon-area {
		padding-bottom: 37px;
		background-position: center;
		margin-bottom: 30px
	}
	.award-text-area{
		text-align:center;
	}
	.each-category:last-child{
		display:none;
	}
	.each-category {
		width: calc(50% - 5px);
	}
	.all-category-listing {
		flex-wrap: wrap; 
		gap: 5px;
		justify-content:space-between
	}
	.each-category{
		margin-bottom:20px;
	}
	.view-all-button {
		margin-top: 20px;
	}
	.featured-seller-products {
		gap: 5px;
		justify-content:space-between
	}
	.featured-seller-products > div {
		width: calc(50% - 5px);
	}
	.each-featured-product {
		margin-bottom:20px;
	}
	.featured-product-wrapper  {
		gap: 5px;
		justify-content: space-between;
	}
	.featured-product-wrapper .each-selling-product {
		width: calc(50% - 5px);
	}
	.featured-product-wrapper .each-selling-product {
		margin-bottom: 20px;
	}
	.featured-product-wrapper .each-selling-product:last-child{
		display:none;
	}
	.featured-product-area {
		padding: 50px 0px 20px;
	}
	.wishlist-product-wrapper .each-selling-product {
		width: calc(33.33% - 15px);
	}
	.filter-area{
		display:none;
	}
	.filter-select-list {
		gap: 15px;
		flex-wrap: nowrap;
		margin-bottom: 20px;
		white-space: nowrap;
		overflow-x: scroll;
	}
	.each-select-result p {
		font-size: 14px;
	}
	.filter-result-area {
		padding-left: 0px;
	}
	.product-details-content {
		padding-left: 0px;
	}
	.product-details {
		padding: 0px 0px;
	}
	.header-icons-area{
		position: fixed;
		bottom: 0px;
		left: 0px;
		width: 100%;
		background: #272829;
		padding: 12px 15px 10px;
	}
	.header-icons-area ul{
		max-width:80%;
		justify-content:space-around;
		margin:0px auto;
	}
	.each-cart-item-top {
		padding: 20px 10px;
	}
	.about-us-section .about-us-text {
		padding-left: 0px;
		padding-top:30px;
	}
	.kirloskar-brothers-banner-heading {
		position: relative;
		top: 0;
	}
	.kirloskar-brothers-banner-section {
		padding-top: 120px;
		padding-bottom: 120px;
		position: relative;
	}
	.dashboare-area > div.left-panel, .notification-dropdown{
		position:fixed;
		bottom:0px;
		left:0px;
		width:100%;
		background:#f3f3f3;
		z-index:999999999;
		padding-left: 15px;
		padding-right: 15px;
		padding-bottom: 70px;
		display:none;
		max-height: 70vh;
		overflow-y: scroll;
	}
	.dashboare-area > div.left-panel.active{
		display:block;
	}
	.dashboare-area .left-panel .view-all-button{
		display:none;
	}
	.logo-and-info {
		/* padding-bottom:20px;
		border-bottom:1px solid #dddddd;
		margin-bottom: 15px; */
		padding: 0px !important;
	}
	.logo-and-info h2 {
		margin-bottom: 5px;
	}
	.profile-logo{
		margin-bottom:20px;
	}
	.left-nav-area a.active {
		background: transparent;
		color: #499bce;
		border-left: 3px solid transparent;
	}
	.header-icons-area ul li.profile{
		position:relative;
	}
	.header-icons-area ul li.profile:after{
		position:absolute;
		top: -24px;
		left: -3px;
		content:url('../images/profile-after.png');
		display:none;
	}
	.header-icons-area ul li.profile.active:after{
		display:block;
	}
	.tooltiptext{
		display:none;
	}
	.banner-promotion-banner{
		display:block !important;
	}
	.banner-promotion-content {
		padding-left: 0px;
		padding-top:30px;
	}
	.table-bordered thead td, .table-bordered thead th {
		padding: 10px 20px;
		font-size: 14px;
	}
	.table-bordered td, .table-bordered th {
		padding: 10px 20px;
		font-size: 14px;
	}
	.hs-code-list {
		display: block;
	}
	.hs-code-list li{
		line-height:30px;
	}
	.buyer-dashboard .right-panel-topbar{
		display:flex;
		align-items:center;
		justify-content:space-between;
	}
	.buyer-dashboard .dashboare-area{
		display:block
	}
	.buyer-dashboard .dashboare-area > div.right-panel{
		display:block
	}
	.cart-item-details-top{
		min-height:100px;
	}
	.tab-content .msger {
		min-height: 400px;
	}
	.product-listing-pagination ul{
		justify-content:center;
	}
	.all-category-listing-wrapper .all-category-listing .each-category {
		width: calc(33.33% - 15px);
		margin-bottom: 30px;
	}
	.all-category-listing-wrapper .all-category-listing {
		gap:15px;
	}
	.profile .signup-dropdown{
		display:none !important;
	}
	.notification-dropdown{
		width:100%;
		position:fixed;
		top:0px;
		left:0px;
	}
	.notification-dropdown {
		width: 100%;
		position: fixed;
		top: auto;
		left: 0px;
		display: none;
		z-index: 9999999;
		opacity: 1;
		right: auto;
		bottom: 78px;
	}
	.notification-dropdown ul{
		max-width:100%;
	}
	.notification-area.active .notification-dropdown{
		display:block;
	}
}
@media only screen and (max-width: 800px) {
	#no-more-tables td.hs-list:before{
		margin-top:5px;
	}
	.banner-related-info #no-more-tables table{
		padding:30px 0px 0px;
		border-left:0px;
		border-bottom:0px;
		border-right:0px;
	}
    .banner-promotion-area {
		padding-bottom: 30px;
	}
    /* Force table to not be like tables anymore */
	#no-more-tables table, 
	#no-more-tables thead, 
	#no-more-tables tbody, 
	#no-more-tables th, 
	#no-more-tables td, 
	#no-more-tables tr { 
		display: block; 
	}
 
	/* Hide table headers (but not display: none;, for accessibility) */
	#no-more-tables thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
 
	#no-more-tables tr { border: 0px solid #ccc; padding: 0px 15px;}
 
	#no-more-tables td { 
		/* Behave  like a "row" */
		border: none;
		border-bottom: 0px solid #eee; 
		position: relative;
		padding-left: 50%; 
		white-space: normal;
		text-align:right;
		padding-right: 0px;
		font-size: 14px;
	}
 
	#no-more-tables td:before { 
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 10px;
		left: 0px;
		width: 45%; 
		padding-right: 10px; 
		white-space: nowrap;
		text-align:left;
		font-size: 14px;
		text-transform: uppercase;
	}
	.transaction-history #no-more-tables tr:nth-child(even) {
		background:#f4f6f8;
	}
	/*
	Label the data
	*/
	#no-more-tables td:before { content: attr(data-title); }
}
@media(max-width:767px){
	.buyer_custom_btn{
		width: 40% !important;
	}
	.cart_button button{
		width: auto !important;
	}
	.select2-container{
		width: 100% !important;
	}
	.site-logo img{
		height:23px;
	}
	.mobile-right-side > div img {
		height: 19px;
	}
	.mobile-right-side {
		gap: 15px;
	}
	.banner-area {
		min-height: 440px;
	}
	h2 {
		font-size: 34px;
	}
	.our-vertical-services .row, .engiconnect-services .row{
		margin:0px -10px;
	}
	.our-vertical-services .row .col-6, .engiconnect-services .row .col-6{
		padding:0px 10px;
	}
	.each-verticals {
		margin-bottom: 20px;
		min-height: 175px;
	}
	.each-verticals h3 {
		margin-top:15px;
	}
	.what-is-engeconnect-area h2 img {
		height: 45px;
		margin-top:15px;
	}
	.our-verticals-area {
		padding: 50px 0px 50px;
	}
	.each-what-is-engiconnect-box{
		margin-bottom:20px;
	}
	.count-wrapper > div {
		font-size: 40px;
	}
	.video-button-area {
		display:block !important;
		text-align: center;
		margin-top: 0px;
	}
	.video-button-area a:first-child{
		margin-bottom:20px;
	}
	.counter-area {
		padding: 25px 0px 0px;
	}
	.each-form-field-box button {
		width:100%;
	}
	.buyer-signup-area {
		padding: 35px 0px 20px;
	}
	.banner-area.inner-banner-area {
		min-height: 350px;
		align-items:start;
		padding-top:50px;
	}
	.inner-banner-area h1 {
		padding-left: 0%;
		text-align:center
	}
	/* .promoted-content-text{
		display:none;
	} */
	.promotion-slider-area .swiper-pagination-bullet {
		width: 10px;
		height: 10px;
	}
	.promotion-slider-area .swiper-pagination {
		margin-top: 15px;
	}
	.seller-heading h2 {
		font-size: 21px;
		margin-bottom: 25px;
	}
	.award-text-area h3 {
		font-size: 18px;
		margin-bottom: 16px;
	}
	.sellers-result .swiper-button-next {
		right: 5px;
	}
	.sellers-result .swiper-button-prev {
		left: 5px;
	}
	.best-selling-product-slider-area .swiper-button-next, .best-selling-product-slider-area1 .swiper-button-next {
		right: 5px;
	}
	.best-selling-product-slider-area .swiper-button-prev, .best-selling-product-slider-area1 .swiper-button-prev {
		left: 5px;
	}
	.each-selling-product-content {
		padding-top: 20px;
	}
	.best-selling-product {
		padding: 40px 0px 40px;
	}
	.recently-browsed .each-selling-product-content h3 {
		font-size: 12px;
	}
	.recently-browsed .each-selling-product-content p {
		font-size: 9px;
		padding:0px 5px;
	}
	.featured-product-wrapper .each-selling-product-content h3 {
		font-size: 12px;
	}
	.featured-product-wrapper .each-selling-product p {
		font-size: 9px;
	}
	.featured-product-area {
		padding: 30px 0px 10px;
	}
	.inner-banner-area h1, .buyer-signup-wrapper h1 {
		font-size: 22px;
	}
	.wishlist-product-wrapper .each-selling-product {
		width: calc(50% - 10px);
		margin-bottom:10px;
	}
	.wishlist-product-wrapper {
		gap: 10px;
		justify-content:space-betwwen
	}
	.wishlist-product-wrapper .each-selling-product-content h3 {
		min-height: 33px;
		font-size:13px;
	}
	.wishlist-product-wrapper .each-selling-product-content p {
		font-size: 11px;
	}
	.wishlist-product-wrapper .each-selling-product-content a {
		font-size: 14px;
	}
	.wishlist-product-wrapper .each-selling-product-content {
		padding-top: 13px;
	}
	.each-footer-info h3 {
		font-size: 20px;
		margin-bottom: 25px
	}
	.content-area a {
		font-size: 13px;
	}
	.quick-links ul li a {
		font-size: 14px;
	}
	.quick-links ul li{
		margin-bottom:10px;
	}
	.buyer-signup-wrapper h1{
		margin-bottom:25px;
	}
	.product-listing-slider-image-left{
		display:block !important;
	}
	.product-listing-slider-left img.for-mobile{
		display:block;
	}
	/*.product-listing-slider-left img.for-desktop{
		display:none;
	}*/
	.product-listing-slider {
		width: 100%;
		padding:0px;
	}
	.product-listing-slider-image {
		display: block;
		text-align: center;
	}
	.product-listing-slider-image img {
		max-width: 100%;
	}
	.product-listing-slider-left {
		margin-bottom:20px;
	}
	.product-listing-slider-area {
		padding: 40px 0px 50px;
	}
	.mobile-filter-area h4 {
		font-size: 14px;
	}
	.mobile-short-by-area .each-dropdown-category-wrapper h5 {
		font-size: 14px;
		padding: 14px 0px;
	}
	.search-listing .row{
		margin:0px -7px;
	}
	.search-listing .row .col-lg-4{
		padding:0px 7px;
	}
	.promoted-text, .featured-text {
		font-size: 10px;
		top: 15px;
		left: -60px;
	}
	.each-select-result {
		padding: 5px 10px 5px 12px;
	}
	.each-search-listing-content h6 {
		font-size: 13px;
	}
	.each-search-listing-content p {
		font-size: 11px;
	}
	.each-search-listing-content h5{
		font-size:14px;
	}
	.each-search-listng {
		margin-bottom: 15px;
	}
	.product-price h4 {
		font-size: 13px;
	}
	.each-footer-vertical-icons{
		width:50%;
	}
	.product-listing-slider-area .swiper-pagination-bullet {
		width: 10px;
		height: 10px;
	}
	.product-listing-slider-controller .swiper-pagination {
		margin-top: 10px;
	}
	.each-select-result p {
		font-size: 12px;
	}
	.product-details .container{
		padding:0px;
	}
	.product-details .common-arrow img{
		width:30px;
	}
	.product-details-content{
		padding:30px 15px 0px;
	}
	.product-description p {
		font-size: 13px;
	}
	.product-description h1 {
		font-size: 18px;
		margin-bottom:15px;
	}
	.sold-by p {
		font-size: 13px;
	}
	.button-area {
		display:block !important
	}
	.button-area a{
		width:100%;
	}
	.button-area a:first-child{
		margin-bottom:20px;
	}
	.button-area {
		margin: 23px 0px 10px;
	}
	.code-area p {
		font-size: 13px;
		margin-bottom: 8px;
	}
	.each-selling-product-content h3 {
		font-size: 14px;
		min-height:30px;
	}
	.cart-item-details {
		padding-left: 15px;
	}
	.cart-item-image {
		min-width: 100px;
		max-width: 100px;
		height: 100px;
	}
	.cart-item-details-top-left p {
		font-size: 13px;
		margin-bottom: 5px;
	}
	.cart-item-details-top-left h4 {
		font-size: 14px;
	}
	.cart-item-details-top-right input {
		width: 80px;
		height: 35px;
		font-size: 14px;
	}
	.cart-item-details-bottom {
		display:block !important;
		margin-left: -55vw;
		left: 50%;
		position:relative;
	}
	.each-cart-items-bottom div a {
		font-size: 14px;
		padding: 0px 25px;
	}
	.each-cart-items-bottom {
		padding: 15px 25px;
	}
	.send-enqury-button {
		margin-top: 30px;
	}
	.inquired-products .each-selling-product-content h3 {
		min-height: 30px;
	}
	.mobile-seller{
		padding-right:87px;
	}
	.otp-expire {
		position: relative;
		right: -87px;
	}
	.form-style {
		/*height: 35px;
		line-height: 35px;*/
		font-size: 13px;
		padding: 2px 8px;
	}
	.otp-expire {
		font-size: 10px;
	}
	.login-text p {
		font-size: 13px;
	}
	.click-verify button {
		padding: 0px 19px;
		height: 35px;
		font-size: 13px;
	}
	.new-mobile-buyer .click-verify{
		top:0px;
	}
	.new-mobile-buyer .click-verify button{
		height:100%;
	}
	.resend-otp {
		font-size: 14px;
	}
	.is-verifing {
		font-size: 13px;
		margin-top: 13px;
	}
	.each-form-field-box label, .mobile-seller label {
		font-size: 14px;		
		margin-bottom: 8px;
	}
	.each-form-field-box {
		margin-bottom: 16px;
	}
	.member-login span {
		font-size: 11px;
	}
	.common-button {		
		font-size: 15px;		
		padding: 9px;		
		min-width:0px;		
	}
	.gp-mobile{
		margin-bottom:15px;
	}
	.custom-file-label {
		height: 35px;
		padding-top: 7px;
	}
	.custom-file-label::after {
		height: 35px;
		line-height: 19px;
	}
	.seller-registration h1 {
		font-size: 25px;
		margin-bottom: 20px;
	}
	.tab-from > div:last-child {
		width: 100%;
	}
	.product-filter-area {
		padding: 0px 15px 15px;
	}
	.each-date-box .wrapper{
		display:block !important;
		margin-bottom:15px;
	}
	.each-pricing-info{
		margin-bottom:10px;
	}
	.charges-type .each-pricing-info{
		margin-bottom:0px;
	}
	.charges-type .pricing-radio-content h5 {
		margin-bottom:0px;
	}
	.charges-type .row {
		margin-bottom:20px;
	}
	.company-logo-wrapper {
		margin-bottom: 15px;
	}
	.wrapper h6 {
		margin-right: 0px;
		margin-bottom:15px;
	}
	.dashboard-form-area {
		padding: 30px 15px;
	}
	.each-select-box-area select {
		margin-bottom: 15px;
	}
	.right-panel-topbar .view-all-button{
		display:none;
	}
	.buyer-dashboard .right-panel-topbar .view-all-button {
		display: block;
	}
	.buyer-dashboard .right-panel-topbar .view-all-button a{
		padding:10px 15px !important;
		min-width: 100px;
	}
	.banner-edit .company-edit-delete{
		padding-left:0px;
		padding-top:20px;
	}
	.banner-edit .company-edit-delete ul{
		display:flex;
		gap:20px;
	}
	.banner-edit .company-logo-area img{
		width:100%;
	}
	.banner-image-area{
		margin-top:30px;
	}
	.dashboare-area, .right-panel {
		display: block !important; 
	}
	.products-edited-images .row{
		margin:0px -8px;
	}
	.products-edited-images .row .col-6{
		padding:0px 8px;
		margin-bottom:16px;
	}
	.upload-btn-wrapper {
		width: 100%;
	}
	.buyer-dashboard hr {
		margin-left: -15px;
	}
	.all-category-listing-wrapper .all-category-listing .each-category {
		width: calc(50% - 15px);
		margin-bottom: 10px;
	}
	.all-category-listing-wrapper .all-category-listing {
		gap:15px;
	}
	.all-seller-wrapper .promoted-text{
		left: -65px;
	}
	.kirloskar-brothers-banner-section h1 {
		font-size: 22px;
	}
	.kirloskar-brothers-banner-heading{
		display:block !important;
		text-align:center;
	}
	.kirloskar-brothers-banner-heading img {
		margin-right: 0px;
		margin-bottom:30px;
		max-width: 100px;
	}
	.kirloskar-brothers-banner-section {
		padding-top: 60px;
		padding-bottom: 60px;
		position: relative;
	}
	.about-us-section {
		padding-top: 30px;
		padding-bottom: 30px;
	}
	.about-us-section h2 {
		font-size: 21px;
	}
	.charges-area-heading h2 {
		font-size: 21px;
	}
}
@media(min-width:768px){
	.nav{
		width: 100%;
	}
	.description-mobile, .category-mobile, .h-code-mobile{
		display:none;
	}
}
@media(max-width:1200px){
	.cart_button button{
		width: 20vw !important;
	}
	.your-product-listing table tr td, .your-product-listing table tr th {
		padding: 8px 6px;
		font-size:13px;	
	}
	.select2-container{
		width: 100% !important;
	}
}
@media(max-width:767px){
	.description-desktop, .category-desktop, .h-code-desktop, .snum{
		display:none;
	}
	.your-product-listing table thead{
		display:none;
	}
	.tab-from {
		display: block;
	}
	.tab-from > div {
		display: block;
		padding-right: 0;
	}
	.tab-from > div label{
		margin-bottom:10px;
	}
	.common-button {
		padding: 14px !important;
	}
	.product-filter-button-area a{
		width:100%;
		border-radius:0px;
	}
	.your-product-listing table tr {
		position: relative;
		padding-bottom:50px;
	}
	.your-product-listing table tr td{
		padding: 15px 15px 60px;
	}
	.action-area{
		position: absolute;
		width: 100%;
		left: 0px;
		bottom: 0px;
		display:table;
		border-top: 1px solid #dee2e6;
	}
	.action-area br{
		display:none;
	}
	.action-area a{
		display:table-cell;
		margin:0px;
		padding:7px;
		text-align:center;
		font-size:16px;
	}
	.description-mobile{
		margin:5px 0px 10px;
	}
	.right-panel-topbar {
		padding: 12px 15px;
		min-height: 70px;
	}
	.banner-promotion-area .banner-promotion-heading {
		padding: 30px 15px;
	}
	.company-logo-wrapper {
		display:block !important;
	}
	.banner-promotion-area .banner-promotion-heading a.common-button {
		width:100%;
		margin-top:30px;
	}
	.each-select-box-area {
		margin-bottom:0px !important;
	}
	.company-logo-wrapper p {
		font-size: 14px;
	}
	.msg-text {
		font-size: 12px !important;
	}
	.msger-input {
	  font-size:12px !important;
	}
	.msger-inputarea * {
		padding: 2px 6px;
	}
	.chat-search-area input {
		padding: 8px 15px;
		font-size: 12px;
	}
	.close-convertion-popup p {
		font-size: 12px !important;
		margin-bottom: 15px !important;
	}
	.close-convertation-button a {
		padding: 8px !important;
		font-size: 14px;
	}
	.inactive-message-send-area p {
		font-size: 12px !important;
	}
	.card-header {
		padding: 10px 15px;
		border-radius:0px !important;
	}
	.card-header h3 {
		font-size: 14px;
	}
	.card-header p {
		font-size: 14px;
		margin-bottom: 6px;
	}
	.card-body{
		padding:10px 15px 0px;
	}
	.each-chathistorybox h4 {
		font-size: 14px;
		margin-bottom: 12px;
	}
}
@media(min-width:992px) and (max-width:1199px){
	.date-area .row .col-lg-4{
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
}
@media(max-width:450px){
	.msger{
		right:0px !important;
	}
}