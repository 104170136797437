@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
/*font-family: 'Poppins', sans-serif;*/
body{
	font-family: 'Poppins', sans-serif;
}
/************* HEADER *************/

.site-header{
	position:relative;
	z-index:99999;
}
.hedaer-right-side-icon{
	gap:50px;
}
.header-top-bar{
	padding:24px 0px;
	box-shadow: 0px 0px 13px 0px rgba(0,0,0,0.55);
	-webkit-box-shadow: 0px 0px 13px 0px rgba(0,0,0,0.55);
	-moz-box-shadow: 0px 0px 13px 0px rgba(0,0,0,0.55);
}
.main-menu-bar{
	background:#272829;
}
.dextop-menu {
	padding:0px;
	list-style:none;
	margin:0px;
}
.dextop-menu > li{
	display:inline-block;
	position:relative;
}
.dextop-menu > li > a{
	display:inline-block;
	text-decoration:none;
	font-weight:600;
	color:#fff;
	padding:16px 21px;
	font-size:17px;
}
.dextop-menu > li > a:hover, .dextop-menu > li > a.active{	
	color:#fff;
	background:#1a83c6;
}
.dextop-menu > li > ul, .signup-dropdown, .notification-dropdown{
	position:absolute;
	top:100%;
	min-width:200px;
	left:0%;
	margin:0px;
	padding:0px;
	list-style:none;
	border-radius:0px;
	padding:0px 0px;
	margin-top:10px;
	opacity:0;
	pointer-events:none;
	transition:all 0.5s;
	-webkit-transition:all 0.5s;
	background:#272829;
}
.signup-dropdown> ul{
	padding:0px;
	list-style:none;
	margin:0px;
}
.dextop-menu > li:hover > ul, .sign-up-area:hover .signup-dropdown, 
.header-icons-area > ul > li:hover > .signup-dropdown, 
.header-icons-area > ul > li:hover > .notification-dropdown{
	margin-top:0px;
	opacity:1;
	pointer-events:all;
	transition:all 0.5s;
	-webkit-transition:all 0.5s;
}
/* .dextop-menu > li > ul:before, .signup-dropdown:before, .notification-dropdown:before{
	position:absolute;
	top:-10px;
	left:50%;
	transform:translatex(-50%);
	content:url('../images/Rectangle-517260.png')
} */
.dextop-menu > li > ul > li > a, 
.signup-dropdown> ul > li > a{
	font-size:16px;
	color:#ffffff;
	font-weight:400;
	padding:13px 15px;
	display:block;
	text-decoration:none;
	min-width: 224px;
	transition:all 0.5s;
	-webkit-transition:all 0.5s;
}
.dextop-menu > li > ul > li > a:hover, 
.signup-dropdown> ul > li > a:hover{
	color:#fff;
	transition:all 0.5s;
	-webkit-transition:all 0.5s;
	background: linear-gradient(90deg, rgba(30, 167, 255, 1) 0%, rgba(22, 112, 168, 1) 100%);
}
.dextop-menu > li > ul > li:last-child a{
	border:0px;
}
.dextop-menu > li > img{
	padding-left:20px;
}
.has-submenu > a:after, .has_sub_profile_menu > a:after{
	content:url('../images/down-arrow17260.png');
	margin-left:10px;
	position: relative;
    top: -2px;
}
.serch-area form{
	max-width:280px;
	width:100%;
	border-bottom:1px solid #fff;
	display:flex;
	padding:0px 15px;
}
.serch-area form input{
	background:transparent;
	border:0px;
	border-radius:0px;
	color:#fff;
	width:100%;
	font-size:17px;
	padding:5px 0px;
}
.serch-area form input::placeholder {
	color: #fff;
	opacity: 1;
}

.serch-area form input::-ms-input-placeholder {
	color: #fff;
}
.serch-area form input:focus{
	outline:none;
}
.serch-area form button{
	background:transparent;
	border:0px;
	border-radius:0px;
	padding:0px;
}
.sign-up-area a{
	display:inline-block;
	text-decoration:none;
	font-weight:600;
	color:#fff;
	padding:15px 21px;
	font-size:17px;
}
.sign-up-area a img{
	margin-right:12px;
}
.menu-bar{
	margin-right:40px;
}
.mobile-right-side{
	gap:30px;
}
.mobile-right-side > div img{
	width:auto;
	height:25px;
}
.header-icons-area ul{
	margin:0px;
	padding:0px;
	list-style:none;
}
.header-icons-area > ul{
	display:flex;
}
.header-icons-area ul li{
	position:relative;
	
}
.header-icons-area > ul > li{
	padding:0px 15px;
}
.header-icons-area > ul > li.active, .header-icons-area > ul > li:hover{
	background:#1a83c6;
}
.header-icons-area > ul > li.active .notification-count, .header-icons-area > ul > li:hover .notification-count{
	color:#000;
	background-color: #fff;
}
.header-icons-area > ul > li > a{
	padding:16px 0px;
	display:inline-block;
}
.header-icons-area ul li .notification-count{
	position: absolute;
    font-size: 9px;
    color: #fff;
    background: #1a83c6;
    border-radius: 100%;
    padding: 1px 5px;
    top: 12px;
    left: 24px;
}

.header-icons-area ul li .tooltiptext {
  visibility: hidden;
  width: max-content;
  background-color: #1a83c6;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  bottom: 85%;
  left: 50%;
  transform:translatex(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  font-size:12px;
}

.header-icons-area ul li .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #1a83c6 transparent transparent transparent;
}

.header-icons-area ul li:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
  min-width: 70px;
}
/************* Banner Area **************/
.banner-area{
	min-height:492px;
	display:flex;
	align-items:center
}
/************* Our Vertical Css **************/
.our-verticals-area{
	padding:50px 0px 25px;
}
.our-verticals-area h2{
	margin-bottom:45px;
}
.each-verticals{
	min-height:284px;
	background: linear-gradient(to bottom,  rgba(29,167,255,1) 0%,rgba(27,156,239,1) 23%,rgba(27,152,232,1) 28%,rgba(29,152,232,1) 30%,rgba(27,150,228,1) 31%,rgba(27,149,226,1) 36%,rgba(25,141,214,1) 44%,rgba(26,140,213,1) 47%,rgba(25,139,210,1) 47%,rgba(26,134,206,1) 53%,rgba(23,123,185,1) 71%,rgba(24,123,188,1) 71%,rgba(24,122,187,1) 72%,rgba(22,120,181,1) 75%,rgba(24,119,183,1) 76%,rgba(22,118,178,1) 79%,rgba(24,114,174,1) 87%,rgba(22,113,170,1) 89%,rgba(22,111,169,1) 95%,rgba(21,110,166,1) 95%,rgba(22,111,167,1) 96%,rgba(20,109,165,1) 97%,rgba(23,109,166,1) 99%,rgba(21,107,164,1) 100%);
	box-shadow: 3px 4px 13px 3px rgba(0,0,0,0.29);
	-webkit-box-shadow: 3px 4px 13px 3px rgba(0,0,0,0.29);
	-moz-box-shadow: 3px 4px 13px 3px rgba(0,0,0,0.29);
	border-radius:10px;
}
.each-verticals h3{
	color:#fff;
	text-transform:uppercase;
	font-family: 'Poppins', sans-serif;
	font-weight:600;
	font-size:17px;
	letter-spacing:2px;
	margin-top:25px;
	margin-bottom:0px;
}
.each-verticals img{
	transition:all 0.5s;
	-webkit-transition:all 0.5s;
}
.each-verticals:hover img{
	transform: rotateY(150deg);
	transition:all 0.5s;
	-webkit-transition:all 0.5s;
}
.our-vertical-services{
	margin-bottom:37px;
}

/****************** What Is Engi Connect ******************/
.what-is-engeconnect-area{
	padding:55px 0px 40px;
}
.what-is-engeconnect-area h2 img{
	position:relative;
	top:-5px;
}
.each-what-is-engiconnect-box{
	background: linear-gradient(to bottom,  rgba(29,167,255,1) 0%,rgba(27,156,239,1) 23%,rgba(27,152,232,1) 28%,rgba(29,152,232,1) 30%,rgba(27,150,228,1) 31%,rgba(27,149,226,1) 36%,rgba(25,141,214,1) 44%,rgba(26,140,213,1) 47%,rgba(25,139,210,1) 47%,rgba(26,134,206,1) 53%,rgba(23,123,185,1) 71%,rgba(24,123,188,1) 71%,rgba(24,122,187,1) 72%,rgba(22,120,181,1) 75%,rgba(24,119,183,1) 76%,rgba(22,118,178,1) 79%,rgba(24,114,174,1) 87%,rgba(22,113,170,1) 89%,rgba(22,111,169,1) 95%,rgba(21,110,166,1) 95%,rgba(22,111,167,1) 96%,rgba(20,109,165,1) 97%,rgba(23,109,166,1) 99%,rgba(21,107,164,1) 100%);
	box-shadow: 3px 4px 13px 3px rgba(0,0,0,0.29);
	-webkit-box-shadow: 3px 4px 13px 3px rgba(0,0,0,0.29);
	-moz-box-shadow: 3px 4px 13px 3px rgba(0,0,0,0.29);
	border-radius:10px;
	overflow:hidden;
	margin-bottom: 30px;
}
.text-on-image{
	position:absolute;
	bottom:10px;
	left:0px;
	width:100%;
	text-align:center;
}
.text-on-image h3{
	font-size:17px;
	color:#fff;
	font-weight:400;
}
.more-button {
	margin-top:20px;
}
.engiconnect-services{
	margin-top:27px;
}
.each-what-is-engiconnect-box img{
	transform:scale(1);
	transition:all 0.5s;
	-webkit-transition:all 0.5s;
}
.each-what-is-engiconnect-box:hover img{
	transform:scale(1.1);
	transition:all 0.5s;
	-webkit-transition:all 0.5s;
}
/*************** Counter Area ******************/
.counter-area{
	background-color:#10517a;
	padding:45px 0px;
}
.count-wrapper > div{
	font-family: 'LEMON MILK Bold';
	font-size:52px;
	color:#ffb300;
}
.each-counter-box h4{
	font-size:17px;
	font-weight:600;
	color:#fff;
}
/**************** Video Area *******************/
.video--area{
	max-width:1000px;
}
.adhd-top-video {
    width: 100%;
    height: 100%;
    /*max-width: 1034px;
    max-height: 543px;*/
    margin: 0 auto;
}
.adhd-top-video .adhd-top-video2 {
    position: relative;
    padding-top: 25px;
    padding-bottom: 67.5%;
    height: 0;
}
.adhd-top-video iframe {
    box-sizing: border-box;
    background: url('../images/laptop.png') center center no-repeat;
    background-size: contain;
    padding: 10% 14% 15% 16%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.video-button-area{
	gap:40px;
	margin-top:-40px;
	position: relative;
    z-index: 1;
}
.video-part-area{
	padding-bottom:50px;
}
/************ Footer Area *****************/
.footer-area{
	background-color:#001734;
	background-image:url('../images/map-bg.jpg');
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	padding:80px 0px;
}
.each-footer-info ul{
	margin:0px;
	padding:0px;
	list-style:none;
}
.each-footer-info ul li{
	display:flex;
	margin-bottom:20px;
}
.content-area a{
	color:#fff;
	font-weight:500;
	font-size:16px;
	text-decoration:none;
	transition:all 0.5s;
	-webkit-transition:all 0.5s;
}
.content-area a:hover{
	color:#ffc200;
	transition:all 0.5s;
	-webkit-transition:all 0.5s;
}
.icon-area{
	margin-right:10px;
}
.each-footer-info h3{
	font-size:26px;
	color:#fff;
	font-family: 'LEMON MILK Bold';
	text-transform:uppercase;
	letter-spacing:2px;
	position:relative;
	padding-bottom:13px;
	margin-bottom:50px;
}
.each-footer-info h3:after{
	width:34px;
	height:4px;
	background:#ffc200;
	bottom:0px;
	content:'';
	display:block;
	position:absolute;
}
.each-footer-vertical-icons{
	width:136px;
	height:136px;
	border:1px solid #fff;
	border-radius:15px;
	display:flex;
	align-items:center;
	justify-content:center;
}
.each-footer-vertical-icons img{
	height:50px;
}
.each-footer-vertical-icons h4{
	color: #fff;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 0px;
}
.verticals-icons-wrapper{
	gap:10px;
}
.verticals-icons-wrapper{
	margin-bottom:18px;
}
.quick-links ul{
	margin:0px;
	padding:0px;
}
.quick-links ul li{
	position:relative;
	padding-left:15px;
}
.quick-links ul li a{
	font-size:17px;
	color:#fff;
	font-weight:400;
	text-decoration:none;
	transition:all 0.5s;
	-webkit-transition:all 0.5s;
}
.quick-links ul li a:hover{
	color:#ffc200;
	transition:all 0.5s;
	-webkit-transition:all 0.5s;
}
.quick-links ul li:before{
	width:2px;
	height:2px;
	background:#fff;
	content:'';
	position:absolute;
	top:50%;
	transform:translateY(-50%);
	left:0px;
}
.footer-area-wrapper {
	gap:50px;
}
.footer-area-wrapper > div{
	width:33.33%;
}
/************* Buyer Signup area ****************/
.buyer-signup-area{
	padding:50px 0px 30px;
}
.buyer-signup-wrapper{
	max-width:700px;
}
.buyer-signup-wrapper h1, .all-verticals-area h2{
	text-align:center;
	font-size:31px;
	color:#282828;
	text-align:center;
	font-family: 'LEMON MILK Bold';
	margin-bottom:35px;
}
.each-form-field-box label{
	font-size:16px;
	color:#282828;
	font-weight:700;
	display:block;
	margin-bottom:14px;
}
.form-style{
	height:50px;
	line-height:50px;
	font-size:16px;
	color:#282828;
	font-weight:400;
	width:100%;
	border:1px solid #b0b0b0;
	border-radius:0px;
	padding:0px 20px;
	font-family: 'Poppins', sans-serif;
}
.each-form-field-box{
	margin-bottom:25px;
}
.each-form-field-box button{
	border-radius:0px;
	border:1px solid transparent;
}
.each-form-field-box button:hover{
	border:1px solid #282828;
}
.login-text a{
	color:#1e85c7;
}
.login-text p{
	margin-bottom:5px;
}
.all-verticals-area{
	padding:50px 0px;
}
.banner-bottom-slider-area{
	padding:35px 0px;
}
.slider-content-area{
	max-width:830px;
}
.slider-content-area p{
	font-size:16px;
	color:#272829;
}
.promotion-slider-area .swiper-pagination{
	width:100%;
	margin-top:30px;
	position:relative;
}
.promotion-slider-area .swiper-pagination-bullet {
    width: 17px;
    height: 17px;
    background: #cdcdcd;
    opacity: 1;
	margin: 0px 7px;
}
.promotion-slider-area .swiper-pagination-bullet.swiper-pagination-bullet-active{
	width:47px;
	background:#1a84c6;
	border-radius: 10px
}
.promotion-slider-area{
	margin-top:25px;
}
.verified-box{
	position:absolute;
	top:-1px;
	left:-1px;
}
.promoted-content-text{
	position:absolute;
	right:0px;
	bottom:0px;
}
.promoted-content-text p{
	font-size:16px;
	color:#272829;
	font-weight:400;
}
.seller-of-the-month-area{
	background:#f4f4f4;
	padding:30px 0px;
}
.seller-heading h2{
	font-size:31px;
	color:#282828;
	font-family: 'LEMON MILK Bold';
	margin-bottom:35px;
}
.award-icon-area{
	min-width:195px;
	height:214px;
	background-image:url('../images/SOM.png');
	background-size:contain;
	background-repeat:no-repeat;
	display: flex;
    align-items: center;
    justify-content: center;
	padding-bottom: 36px;
}
.award-text-area{
	padding-left:30px;
}
.award-text-area h3{
	font-size:20px;
	color:#282828;
	font-family: 'LEMON MILK Bold';
	margin-bottom:22px;
}
.award-text-area p{
	color:#595959;
	font-size:16px;
	margin:0px;
}
.category-image{
	border:1px solid #d6d6d6;
	overflow:hidden;
}
.all-category-listing{
	/*flex-wrap:wrap;*/
	gap:15px;
}
.each-category{
	width:20%;
	overflow:hidden;
}
.all-categories{
	padding:45px 0px;
}
.category-name h4{
	font-size:16px;
	font-weight:600;
	color:#272829;
	margin-bottom:0px;
	margin-top:15px;
}
.each-category .category-image img{
	transform:scale(1);
	transition:all 0.5s;
	-webkit-transition:all 0.5s;
}
.each-category:hover .category-image img{
	transform:scale(1.2);
	transition:all 0.5s;
	-webkit-transition:all 0.5s;
}
.each-category a:hover{
	text-decoration:none;
}
.view-all-button{
	margin-top:40px;
}
.seller-filters-listing ul{
	display:flex;
	padding:0px;
	margin:0px;
	list-style:none;
	gap:7px;
	flex-wrap:wrap;
	justify-content:center;
}
.seller-filters-listing ul li a{
	font-size:16px;
	font-weight:400;
	color:#282828;
	padding:5px 5px 3px 5px;
}
.seller-filters-listing ul li.active a{
	font-weight:600;
	color:#1a84c6;
	border-bottom:2px solid #1a84c6
}
.sellers-result{
	margin-top:25px;
}
.each-seller-logo img{
	width:100%;
}
.sellers-result .swiper-button-next{
	right: -25px;
}
.sellers-result .swiper-button-prev{
	left: -25px;
}
.each-seller h4{
	margin-top:20px;
}
.all-series-area{
	padding-bottom:50px;
}
.featured-sellers{
	background:#1a83c6;
	padding:40px 0px 50px;
}
.featured-sellers .seller-heading h2{
	color:#fff;
}
.each-featured-product{
	background:#fff;
}
.each-featured-product a{
	font-size:17px;
	color:#1a84c6;
	text-decoration:underline;
	font-weight:600;
	text-transform:uppercase;
	transition:all 0.5s;
	-webkit-transition:all 0.5s;
}
.each-featured-product a:hover{
	color:#000;
	transition:all 0.5s;
	-webkit-transition:all 0.5s;
}
.related-product-content{
	padding:15px 0px 20px;
}
.relaed-icon{
	min-height:68px;
	display:flex;
	align-items:center;
	justify-content:center
}
.featured-seller-products{
	gap:20px;
}
.each-featured-product {
	width:25%;
}
.best-selling-product{
	padding:40px 0px 55px;
}
.each-selling-product-content h3{
	font-size:16px;
	color:#272829;
	font-weight:600;
	margin-bottom:15px;
}
.each-selling-product-content {
	padding-top:30px;
}
.each-selling-product-image{
	overflow:hidden;
}
.each-selling-product-image img{
	width:100%;
	transform:scale(1);
	transition:all 0.5s;
	-webkit-transition:all 0.5s;
	height:100%;
	object-fit:cover;
	object-position: center;
}
.each-selling-product:hover .each-selling-product-image img{
	transform:scale(1.2);
	transition:all 0.5s;
	-webkit-transition:all 0.5s;
}
.best-selling-product-slider-area .swiper-button-next, .best-selling-product-slider-area1 .swiper-button-next{
	right: -22px;
	top:35%;
}
.best-selling-product-slider-area .swiper-button-prev, .best-selling-product-slider-area1 .swiper-button-prev{
	left: -22px;
	top:35%;
}
.seller-logo img{
	width:100%;
}
.featured-product{
	position: absolute;
    top: -9px;
    right: -57px;
    background: #1a83c6;
    /* width: 58px; */
    /* height: 58px; */
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(45deg);
    padding: 28px 47px 6px;
}
.featured-product p{
	font-size:10px;
	color:#fff;margin:0px;
	text-transform:uppercase;
}
.featured-product-area{
	padding:50px 0px;
}
.featured-product-wrapper{
	gap:10px;
}
.featured-product-wrapper .each-selling-product{
	width:calc(20% - 10px);
}
.featured-product-wrapper .each-selling-product p{
	font-size:12px;
	color:#575757;
	margin:0px;
	font-weight:600;
}
.featured-product-wrapper .each-selling-product-image{
	border: 1px solid #d6d6d6;
    overflow: hidden;
}
.featured-product-wrapper .each-selling-product-content {
    padding-top: 15px;
}
.featured-product-wrapper .each-selling-product-content h3 {
    margin-bottom: 10px;
}
.featured-sellers .view-all-button a{
	background:transparent;
	border:1px solid #fff;
	color:#fff;
}
.featured-sellers .view-all-button a:hover{
	background:#fff;
	color:#000;
}
.recently-browsed{
	background:#f7f7f7;
}
.recently-browsed .each-selling-product{
	background:#fff;
}
.recently-browsed .each-selling-product-content h3 {
    font-size: 15px;
}
.recently-browsed .each-selling-product-content p{
	font-size:12px;
}
.recently-browsed .each-selling-product-content h3 {
    font-size: 15px;
    margin-bottom: 5px;
}
.recently-browsed .each-selling-product-content {
    padding-top: 10px;
	padding-bottom: 10px;
}
.wishlist-product-wrapper{
	flex-wrap:wrap;
	gap:15px;
}
.wishlist-product-wrapper .each-selling-product{
	width:calc(20% - 15px);
	margin-bottom: 15px;
}
.wishlist-product-wrapper .each-selling-product-content h3{
	min-height:38px;
	margin-bottom:5px;
}
.wishlist-product-wrapper .each-selling-product-content p{
	font-size:12px;
	margin-bottom:8px;
}
.wishlist-product-wrapper .each-selling-product-content a{
	text-transform:uppercase;
	font-size:16px;
	font-weight:600;
	color:#2087c7;
}
.wishlist-product-wrapper .each-selling-product-image{
	border:1px solid #e8e8e8;
}
.cancel-product{
	position:absolute;
	top:5px;
	right:10px;
}
.product-listing-slider-left{
	min-width:35%;
	overflow:hidden;
	max-width: 440px;
}
.product-listing-slider-left > img{
	height:100%;
	object-fit:cover;
	object-position:center;
}
.product-listing-slider{
	width: calc(100% - 35%);
	gap:7px;
	padding-left:10px;
	justify-content:space-between;
}
.product-listing-slider > div{
	width:calc(33.33% - 7px);
}
.product-price h4{
	margin:0px;
	font-size:17px;
	font-weight:600;
	color:#000;
}
.product-listing-slider-image{
	display:flex;
	align-items:center;
	padding:5px;
	border:1px solid #e8e8e8;
	height:100%;
}
.product-listing-slider .swiper-container{
	height:100%;
}
.yellow-varified{
	position:absolute;
	top:-1px;
	left:-1px;
}
.product-listing-slider-area{
	padding:40px 0px 80px;
}
.product-listing-slider-area .swiper-pagination-bullet {
    width: 17px;
    height: 17px;
    background: #cdcdcd;
    opacity: 1;
	margin: 0px 7px;
}
.product-listing-slider-area .swiper-pagination-bullet.swiper-pagination-bullet-active{
	width:47px;
	background:#1a84c6;
	border-radius: 10px
}
.product-listing-slider-controller .swiper-pagination{
	width:100%;
	margin-top: 25px;
}
.each-dropdown-category-wrapper h5.active:after{
	transform:rotate(180deg);
	top:3px;
}
.related-dropdown {
	padding: 20px 0px 15px;;
	display:none;
}
.related-dropdown.active{
	display:block;
}
.related-dropdown ul li:not(:last-child) {
	margin-bottom: 10px;
}
.filter-area h3{
	font-size:16px;
	color:#242424;
	font-weight:700;
	text-transform:uppercase;
	border-bottom:1px solid #dddddd;
	margin: 0px;
    padding-bottom: 15px;
}
.each-dropdown-category-wrapper h5{
	font-size:16px;
	color:#242424;
	font-weight:700;
	text-transform:uppercase;
	border-bottom:1px solid #dddddd;
	margin: 0px;
	padding:17px 0px;
}
.each-dropdown-category-wrapper h5:before{
	content:'+';
	font-weight:700;
	margin-right:5px;
}
.each-dropdown-category-wrapper h5.active:before{
	content:'-';
}
.each-dropdown-category-wrapper:last-child h5{
	border:0px;
}
.each-dropdown-category-wrapper:last-child h5.active{
	border-bottom:1px solid #dddddd;
}
.filter-area{
	padding:30px 20px 0px;
	border:1px solid #dddddd;
	min-width:325px;
	max-width:325px;
}
.related-dropdown ul {
	margin: 0px;
	padding: 0px;
	list-style: none;
}

.check-label {
	display: block;
	position: relative;
	padding-left: 24px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	color: #000;
	font-size: 16px;
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	margin-bottom: 0;
	line-height: 25px;
}

.check-label input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

.checkmark {
	position: absolute;
	top: 6px;
	left: 0;
	height: 14px;
	width: 14px;
	border: 1px solid #000;
	border-radius: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.check-label input:checked~.checkmark {
	background-color: #000;
}

.check-label .checkmark:after {
	width: 4px;
    height: 8px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    content: "";
    position: absolute;
    display: none;
    top: 1px;
    left: 4px;
}

.check-label input:checked~.checkmark:after {
	display: block;
}
.each-select-result{
	background:#f4f4f4;
	border:1px solid #dddddd;
	padding: 7px 10px 7px 28px;
    border-radius: 5px;
}
.each-select-result p{
	font-size: 16px;
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	color:#000;
	margin:0px;
	margin-right:20px;
}
.filter-select-list{
	gap:23px;
	flex-wrap:wrap;
	margin-bottom:20px;
}
.filter-result-area{
	width:100%;
	padding-left:15px;
}
.sort-by-area{
	border:1px solid #e2e2e2;
	padding: 17px 10px 17px 30px;
	margin-bottom:25px;
}
.search-result-number h5{
	margin:0px;
	font-size: 16px;
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	color:#000;
}
.sort-by-dropdown h5{
	margin:0px;
	font-size: 16px;
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	color:#000;
	margin-right:10px;
}
.sort-by-dropdown select{
	font-size: 16px;
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	color:#000;
	border:1px solid #e2e2e2;
	border-radius:0px;
	text-transform:uppercase;
	min-width:185px;
	padding: 4px 20px;
	appearance: none;
	background:url('../images/Forma-114440.png') no-repeat 95% center;
}
.each-search-listing-content{
	padding:15px;
}
.each-search-listing-content h6{
	font-size:16px;
	font-weight:600;
	text-transform:uppercase;
	margin-bottom: 5px;
}
.each-search-listing-content p{
	font-size:15px;
	font-weight:400;
	margin-bottom:10px;
	display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.each-search-listing-content h5{
	font-size:18px;
	font-weight:600;
	color:#fe2d43;
	margin:0px;
}
.each-search-listng{
	border:1px solid #dddddd;
	overflow:hidden;
	margin-bottom:30px;
	min-height: 300px;
}
.promoted-text, .featured-text{
	position: absolute;
    color: #fff;
    background: #dd9c00;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 400;
    width: 100%;
    text-align: center;
    top: 30px;
    transform: rotate(-45deg);
    left: -100px;
}
.featured-text{
	background:#1a84c6;
}
.each-serch-listing-image img{
	transform:scale(1);
	transition:all 0.5s;
	-webkit-transition:all 0.5s;
}
.each-search-listng:hover .each-serch-listing-image img{
	transform:scale(1.1);
	transition:all 0.5s;
	-webkit-transition:all 0.5s;
}
.product-listing-filter-area {
	align-items:flex-start
}
.product-listing-slider-image img{
	max-width:100%;
	height:153px;
	object-fit:cover;
	object-position:center;
}
.product-listing-slider .swiper-wrapper {
    transition-timing-function: linear !important;
    position: relative;
}
.product-listing-slider .swiper-wrapper .swiper-slide {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    position: relative;
    overflow: hidden;
    padding: 0 7.5px;
}
.mobile-short-by-area{
	border:1px solid #dddddd;
	padding:5px 15px;
	/*margin-bottom:30px;*/
	position:fixed !important;
	bottom:0px;
	left:15px;
	width:calc(100% - 30px);
	background:#fff;
	z-index:9999;
	border-top-right-radius: 10px;
	border-top-left-radius:10px;
	-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.22);
	-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.22);
	box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.22);
}
.mobile-filter-area h4{
	font-size:16px;
	text-transform:uppercase;
	margin:0px;
}
.mobile-filter-area h4 img{
	width:12px;
	margin-right:10px;
}
.mobile-short-by-area > div{
	width:50%;
	text-align:center;
	padding:5px 0px;
}
.mobile-filter-area:first-child{
	border-right:1px solid #dddddd
}
.mobile-short-by-area .filter-area{
	display:none;
	position:absolute;
	bottom:100%;
	left:0px;
	width:100%;
	z-index:9999;
	background: #272829;
	max-width: 100%;
	padding: 15px 20px 0px;
	text-align: left;
	border: 1px solid #272829;
	border-top-right-radius: 10px;
	border-top-left-radius:10px;
	-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.22);
	-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.22);
	box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.22);
	max-height: 80vh;
    overflow-y: scroll;
}
.mobile-short-by-area .each-dropdown-category-wrapper h5 {
    font-size: 16px;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    border-bottom: 1px solid #4d4f51;
    margin: 0px;
    padding: 17px 0px;
}
.mobile-short-by-area .check-label {
    color: #fff;
}
.mobile-short-by-area .checkmark {
    border: 1px solid #9a9b9b;
}
.mobile-short-by-area .check-label .checkmark:after {
    width: 4px;
    height: 8px;
    border: solid #1a84c6;
    border-width: 0 1px 1px 0;
    transform: rotate(45deg);
    content: "";
    position: absolute;
    display: none;
    top: 1px;
    left: 4px;
}
.mobile-short-by-area .filter-area.active{
	display:block;
}
.product-listing-slider-left img.for-mobile{
	display:none;
}
.filter-button-area{
	display:flex;
	gap:10px;
	justify-content:space-between;
	padding:30px 0px;
}
.filter-button-area a{
	font-size: 14px;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 10px;
    display: inline-block;
    width: calc(50% - 10px);
    padding: 13px 0px;
    text-align: center;
}
.filter-button-area a:first-child{
	background:#434446;
}
.wishlist-icon {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 19px;
    color: #ffb400;
    cursor: pointer;
    z-index: 1;
}
.wishlist-icon.active {
    color: #ce2029;
}
.gallery-thumbs{
	margin-top:15px;
}
.product-details{
	padding:40px 0px;
}
.stop-scroll{
	overflow:hidden;
}
.product-description p{
	font-size:16px;
	color:#000;
	margin-bottom:10px;
}
.product-description h1{
	font-size:24px;
	font-family: 'LEMON MILK Rg';
	text-transform:uppercase;
	margin-bottom:20px;
}
.product-details-content{
	padding-left:30px;
}
.sold-by p{
	font-size:16px;
	color:#000;
}
.sold-by p span{
	text-transform:uppercase;
	font-weight:600;
	color:#1a83c6;
	margin-left:10px;
}
.button-area {
	margin:23px 0px 20px;
}
.button-area a{
	border-radius:0px;
	text-transform:uppercase;
	padding-left: 33px;
    padding-right: 33px;
}
.button-area a img{
	margin-right:10px;
	vertical-align: baseline;
}
.button-area a.common-button.gradient-button:hover{
	background: linear-gradient(135deg, rgba(255,179,1,1) 0%,rgba(255,178,0,1) 47%,rgba(215,116,0,1) 100%);
	color:#fff;
}
.button-area a.common-button.gradient-button.gradient-button-orange:hover{
	background: -moz-linear-gradient(90deg, rgba(30, 167, 255, 1) 0%, rgba(22, 112, 168, 1) 100%);
	background: -webkit-linear-gradient(90deg, rgba(30, 167, 255, 1) 0%, rgba(22, 112, 168, 1) 100%);
	background: linear-gradient(90deg, rgba(30, 167, 255, 1) 0%, rgba(22, 112, 168, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1ea7ff", endColorstr="#1670a8", GradientType=1);
	color: #ffffff;
}
.code-area{
	padding-bottom:20px;
	padding-top:20px;
	border-bottom:1px solid #dddddd;
}
.code-area p{
	font-size:16px;
	color:#000;
	text-transform:uppercase;
	margin-bottom:13px;
}
.code-area p:last-child{
	margin:0px;
}
.code-area p strong{
	margin-right:15px;
}
.code-area p a{
	color:#1a8ed8;
	font-weight:600;
	text-transform:capitalize;
	margin-left:5vw;
}
.code-area p a:hover{
	color:#000;
}
.inquired-products{
	background:#f4f4f4;
}
.inquired-products .each-selling-product-content {
    padding-top: 12px;
}
.inquired-products .each-selling-product-content p{
	font-size:12px;
	margin-bottom:5px;
	text-transform:uppercase;
}
.inquired-products .each-selling-product-content h6{
	font-size:12px;
	display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.inquired-products .each-selling-product-content h3 {
    margin-bottom: 8px;
	display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
	min-height:38px;
}
.buyer-signup-wrapper.cart-product-wrapper{
	max-width:780px;
}
.cart-products-heading{
	border:1px solid #e6e6e6;
	padding:22px 55px;
	margin-bottom:20px;
}
.cart-products-heading h5{
	font-size:18px;
	font-weight:600;
	margin:0px;
}
.cart-item-image{
	min-width:150px;
	max-width:150px;
	height:150px;
	overflow:hidden;
}
.cart-item-image img{
	width:100%;
	height:100%;
	object-fit:cover;
}
.each-cart-item-top{
	padding:25px;
	border:1px solid #e6e6e6;
}
.cart-item-details-top-left p {
    text-transform: uppercase;
    font-size: 16px;
    margin-bottom: 5px;
}
.cart-item-details-top-left h4{
	margin:0px;
	font-family: 'LEMON MILK Rg';
	font-size:16px;
	text-transform: uppercase;
}
.cart-item-details-top-right p{
	font-weight:600;
	font-size:17px;
	margin:0px;
}
.cart-item-details-top-right input{
	width:120px;
	height:57px;
	border:1px solid #4d8c92;
	font-weight:600;
	font-size:17px;
	text-align:center;
	margin-left: 17px;
}
.cart-item-details{
	padding-left:20px;
}
.cart-item-details .code-area {
    padding-bottom: 0px;
    padding-top: 0px;
    border-bottom: 0px solid #dddddd;
}
.cart-item-details-bottom {
	margin-top:35px;
}
.each-cart-items-bottom div a{
	font-size:18px;
	font-weight:600;
	color:#50a0d3;
	text-transform:uppercase;
	padding:0px 45px;
}
.each-cart-items-bottom div:first-child a{
	padding-left:0px;
	border-right:1px solid #e6e6e6
}
.each-cart-items-bottom{
	padding:20px 25px;
	border:1px solid #e6e6e6;
	border-top:0px;
}
.each-cart-item{
	margin-bottom:20px;
}
.send-enqury-button a{
	border-radius:0px;
}
.send-enqury-button a img{
	margin-right:10px;
}
.send-enqury-button a:hover{
	background: linear-gradient(135deg, rgba(255,179,1,1) 0%,rgba(255,178,0,1) 47%,rgba(215,116,0,1) 100%);
	color:#fff;
}
.send-enqury-button {
	margin-top:50px;
}
.cart-item-details-top-right p {
    font-size: 14px;
}
.cart-item-details-top-right {
	margin-top:30px;
}
.rating-point{
	position:absolute;
	left:0px;
	top:0px;
	overflow: hidden;
    width: 20%;
}
.feedback-area textarea{
	height:auto;
	resize:none;
}
/***************** ADD BY PRADIP ********************/
.member-login{
	padding-bottom:15px;
}
.member-login label{
	margin-bottom:15px;
}
.member-login input[type="radio"]{
	width:20px;
	height:20px;
	margin-right:10px;
	position:relative;
	bottom:-5px;
}
.member-login span{
	display:inline-block;
	margin-right:20px;
}
.is-verifing{
	position:absolute;
	left:100%;
	margin-left:10px;
	top:50%;
	transform:translateY(-50%);
	color:#1a83c6;
	white-space:nowrap;
	margin-top: 19px;
	font-weight:500;
}
.click-verify{
	position:absolute;
	left:100%;
	margin-left:10px;
	bottom:0px;
}
.click-verify button{
	background: #1a83c6;
    width: auto;
    padding: 0px 19px;
    min-width: 0px;
    height: 50px;
}
.otp-inner{
	display:table;
	width:100%;
	margin-bottom:10px;
}
.otp-inner > div{
	display:table-cell;
	padding:0px 7px;
}
.otp-inner > div:first-child{
	padding-left:0px;
}
.otp-inner > div:last-child{
	padding-right:0px;
}
.otp-inner input{
	text-align:center;
}
.resend-otp{
	color:#1a83c6;
	font-weight:bold;
}
.otp-expire{
	position:relative;
	right: -100px;
}
.verticals span{
	margin-bottom:10px;
}
.seller-registration{
	padding-left:100px;
	padding-right:100px;
}
.seller-registration h1{
    text-align: left;
    font-size: 31px;
    color: #282828;
    font-family: 'LEMON MILK Bold';
    margin-bottom: 35px;
}
.add-ex-p{
	padding-top:10px;
}
.pb-0{
	padding-bottom:0px;
}
.custom-file-label {
	overflow: hidden;
	height:50px;
	border: 1px solid #282828;
	border-radius: 0;
	padding-top: 12px;
	font-weight: 300 !important;
	color: #282828 !important;
}
.custom-file-input {    
    height: 50px;
}
.custom-file-label::after {    
    background-color: #1e85c7;
    border-left: inherit;
    border-radius: 0;
    border: 0;
    color: #fff;
    height: 50px;
    line-height: 34px;
    padding: 7px 21px;
}
.grey-bg{
	background:#f4f6f8;
	margin:10px -30px 30px;
	padding:30px;
}
.dashboare-area{
	display:table;
	width:100%;
}
.dashboare-area > div{
	display:table-cell;
	vertical-align:top;
}
.left-panel{
	width:296px;
	border-right:1px solid #ececec;
	padding-bottom:50px;
	background: #fff;
    position: relative;
    z-index: 1;
}
.right-panel{
	/*width:calc(100% - 296px);*/
}
.logo-and-info{
	padding:30px;
	text-align:center;
}
.logo-and-info h2 {
    text-align: center;
    font-size: 16px;
    color: #282828;
    font-family: 'LEMON MILK Bold';
    margin-top: 15px;
    margin-bottom: 35px;
}
.logo-and-info p{
	font-family: 'Poppins', sans-serif;
}
.profile-logo img{
	width:110px;
	height:110px;
	border-radius:50%;
	overflow:hidden;
	object-fit:cover;
}
.left-nav-area{
	list-style:none;
	margin:0px;
	padding:0px;
}
.left-nav-area a{
	display:block;
	padding:14px 15px;
	color:#696969;
	font-family: 'Poppins', sans-serif;
	font-weight:600;
	border-left:3px solid transparent;
}
.left-nav-area a:hover {
	background:#ececec;
	color:#499bce;
	border-left:3px solid #ececec;
}
.left-nav-area a.active {
	background:#ececec;
	color:#499bce;
	border-left:3px solid #499bce;
}
.left-nav-area a:hover span img, .left-nav-area a.active span img{
	filter: brightness(0) saturate(100%) invert(81%) sepia(39%) saturate(6119%) hue-rotate(174deg) brightness(88%) contrast(82%);
}
.left-nav-area a span{
	display:inline-block;
	width:35px;
}
.left-nav-area li ul{
	list-style:none;
	padding:0px;
	display:none;
}
.left-nav-area li ul.active{
	display:block;
}
.left-nav-area li ul li a {
    padding: 8px 15px 8px 50px;
    font-weight: 400;
}
.right-panel-topbar h2{
    font-size: 16px;
    color: #282828;
    font-family: 'LEMON MILK Bold';
    margin-top: 0px;
    margin-bottom: 0px;
}
.right-panel-topbar{
	display:table;
	width:100%;
	border-bottom:1px solid #ececec;
	padding:12px 30px;
	min-height:80px;
}
.right-panel-topbar > div{
	display:table-cell;
	vertical-align:middle;
}
.seller-dashboard .common-button {    
    padding: 12px;
    min-width: 166px;
}
.dashboard-form-area{
	padding:40px 30px;
}
.dashboard-form-area{
	max-width:1300px;
}
.image-up{
	position:relative;
}
.image-up img{
	width:100%;
	height:110px;
	object-fit:cover;
}
.remove-image-outer{
	position:absolute;
	width:100%;
	height:100%;
	background: rgb(0 0 0 / 71%);
	color:#fff;
	top:0px;
	left:0px;
	display:table;
	cursor:pointer;
	opacity:0;
	transition:all .5s;
}
.remove-image-outer:hover{	
	opacity:1;
}
.remove-image-outer span{
	display:table-cell;
	text-align:center;
	vertical-align:middle;
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  overflow:hidden;
  margin-top:20px;
}



.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.up-btn{		
	border-radius: 10px !important;
	border: 0px solid transparent !important;
	padding-left:40px !important;
	padding-right:40px !important;
}

.common-button-new {
    color: #2087c7 !important;
    text-transform: uppercase;
    border: 2px solid #2087c7 !important;
    border-radius: 0px !important;
    background: none;
	transition:all .5s;
}
.common-button-new:hover{
    color: #fff !important;
    background: #2087c7 !important;
}
.company-logo-wrapper{
	margin-bottom:35px;
}
.company-logo-wrapper h3, .tab-from > div > label{
	font-size: 16px;
    color: #282828;
    font-weight: 700;
    display: block;
    margin-bottom: 14px;
	font-family: 'Poppins', sans-serif;
}
.company-logo-wrapper p{
	color:#595959;
	font-size:16px;
	font-weight:400;
	margin-bottom:25px;
	font-family: 'Poppins', sans-serif;
}
.company-edit-delete ul{
	margin:0px;
	padding:0px;
	list-style:none;
}
.company-edit-delete ul li a{
	font-size:16px;
	color:#2087c7;
	font-weight:700;
	text-transform:uppercase;
}
.company-edit-delete ul li:not(:last-child){
	margin-bottom:16px;
}
.company-edit-delete{
	padding-left:45px;
}
.textarea .form-style{
	height:auto;
	line-height:30px;
	resize:none;
	padding:10px 20px;
}
.your-interest-listing{
	margin-bottom:20px;
}
.your-interest-listing ul{
	margin:0px;
	padding:0px;
	list-style:none;
	display:flex;
	flex-wrap:wrap;
	gap:15px;
}
.your-interest-listing ul li a{
	font-size:14px;
	font-weight:400;
	color:#282828;
	border:1px solid #cacaca;
	border-radius:5px;
	display:inline-block;
	padding:5px 15px;
}
.your-interest-listing ul li.active a{
	color:#fff;
	background:#1b82c5;
}
.add-interest-button{
	font-size:14px;
	font-weight:600;
	color:#282828;
	cursor:pointer;
}
.add-interest-button img{
	margin-right:15px;
}
.add-interest-add-feild input{
	max-width:309px;
	height:37px;
	line-height:37px;
	font-size:14px;
	font-weight:400;
}
.add-interest-add-feild button{
	color:#fff;
	background:#1b82c5;
	font-size:14px;
	border:0px;
	border-radius:0px;
	padding:7px 50px;
}
.add-interest-add-feild button:hover{
	border:0px;
}
.add-interest-add-feild{
	gap:20px;
	display:none;
}
.add-interest-add-feild.active{
	display:flex;
}
.buyers-profile-image-wrapper{
	background:#eeeeee;
	padding:50px 0px 55px;
}
.buyers-profile-image{
	width:170px;
	height:170px;
	border-radius:100%;
	overflow:hidden;
}
.buyers-profile-image img{
	width:100%;
	height:100%;
	object-fit:cover;
}
.buyer-profile-content-wrapper {
	padding:30px 0px 50px;
}
.buyer-profile-content-wrapper p{
	font-size:16px;
	color:#595959;
	margin-bottom:8px;
}
.buyer-profile-content-wrapper h6{
	font-size:16px;
	font-weight:600;
	color:#000;
	margin-bottom:30px;
}
.buyer-profile-content-wrapper a.common-button{
	border-radius:0px;
	margin-top:30px;
}
.buyer-profile-content-wrapper a.common-button:hover{
	background: rgb(30, 167, 255);
	background: -moz-linear-gradient(90deg, rgba(30, 167, 255, 1) 0%, rgba(22, 112, 168, 1) 100%);
	background: -webkit-linear-gradient(90deg, rgba(30, 167, 255, 1) 0%, rgba(22, 112, 168, 1) 100%);
	background: linear-gradient(90deg, rgba(30, 167, 255, 1) 0%, rgba(22, 112, 168, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1ea7ff", endColorstr="#1670a8", GradientType=1);
}
.banner-promotion-content p{
	color:#595959;
	font-size:16px;
	margin-bottom:10px;
}
.banner-promotion-content a{
	color:#2087c7;
	font-weight:700;
	font-size:16px;
}
.banner-promotion-content{
	padding-left:35px;
}
.banner-promotion-heading{
	margin-bottom:20px;
}
.banner-promotion-banner{
	margin-bottom:30px;
}
.pricing-radio-content p{
	font-size:16px;
	color:#595959;
	margin-bottom:2px;
}
.pricing-radio-content h5{
	color:#000;
	font-size:16px;
	font-weight:700;
}
.pricing-radio-content{
	padding-left:20px;
}
.wrapper {
	margin-left: auto;
	margin-right: auto;
}
.wrapper input {
	display:block;
	border-radius: 0px;
	border: 1px solid #b0b0b0;
	padding: 0px 25px;
	font-size: 16px;
	color: #607D8B;
	width: 100%;
	height:51px;
	line-height:51px;
	background:url('../images/datepicker-icon.png') no-repeat 95% center;
}
.wrapper input:focus {outline: none;}
#ui-datepicker-div {
	display: none;
	background-color: #fff;
	box-shadow: 0 0.125rem 0.5rem rgba(0,0,0,0.1);
	margin-top: 0.25rem;
	border-radius: 0.5rem;
	padding: 0.5rem;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
.ui-datepicker-calendar thead th {
	padding: 0.25rem 0;
	text-align: center;
	font-size: 0.75rem;
	font-weight: 400;
	color: #78909C;
}
.ui-datepicker-calendar tbody td {
	width: 2.5rem;
	text-align: center;
	padding: 0;
}
.ui-datepicker-calendar tbody td a {
	display: block;
	border-radius: 0.25rem;
	line-height: 2rem;
	transition: 0.3s all;
	color: #546E7A;
	font-size: 0.875rem;
	text-decoration: none;
}
.ui-datepicker-calendar tbody td a:hover {	
	background-color: #E0F2F1;
}
.ui-datepicker-calendar tbody td a.ui-state-active {
	background-color: #009688;
	color: white;
}
.ui-datepicker-header a.ui-corner-all {
	cursor: pointer;
	position: absolute;
	top: 0;
	width: 2rem;
	height: 2rem;
	margin: 0.5rem;
	border-radius: 0.25rem;
	transition: 0.3s all;
}
.ui-datepicker-header a.ui-corner-all:hover {
	background-color: #ECEFF1;
}
.ui-datepicker-header a.ui-datepicker-prev {	
	left: 0;	
	background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==");
	background-repeat: no-repeat;
	background-size: 0.5rem;
	background-position: 50%;
	transform: rotate(180deg);
}
.ui-datepicker-header a.ui-datepicker-next {
	right: 0;
	background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==');
	background-repeat: no-repeat;
	background-size: 10px;
	background-position: 50%;
}
.ui-datepicker-header a>span {
	display: none;
}
.ui-datepicker-title {
	text-align: center;
	line-height: 2rem;
	margin-bottom: 0.25rem;
	font-size: 0.875rem;
	font-weight: 500;
	padding-bottom: 0.25rem;
}
.ui-datepicker-week-col {
	color: #78909C;
	font-weight: 400;
	font-size: 0.75rem;
}
.wrapper h6{
	font-size:16px;
	color:#595959;
	font-weight:400;
	white-space: nowrap;
	margin-right:20px;
}
.banner-promotion-heading a{
	border-radius:0px;
}
.banner-promotion-area .banner-promotion-heading{
	padding:15px 30px;
	border-bottom: 0px solid #ececec;
	margin:0px;
}
.table-bordered thead td, .table-bordered thead th {
    border-bottom-width: 1px;
	padding: 10px 30px;
	font-weight: 400;
}
.table-bordered td, .table-bordered th {
    border: 1px solid #dee2e6;
	padding: 10px 30px;
	min-width: 160px;
}
.table-striped tbody tr:nth-of-type(odd) {
    background-color: transparent;
}
.each-select-box-area select{
	border:1px solid #cccccc;
	border-radius:0px;
	height:50px;
	line-height:50px;
	background:url('../images/select-dropdown.png') no-repeat 95% center;
	width:100%;
	font-size:16px;
	padding:0px 15px;
	appearance: none;
	font-family: 'Poppins', sans-serif;
}
.add-more a{
	border:1px solid #2796dc;
	font-size:16px;
	height:50px;
	line-height:50px;
	min-width:142px;
	padding:0px 30px;
	text-align:center;
	display:inline-block;
}
.add-more a:hover{
	background:#2796dc;
	color:#fff;
}

.transaction-history .table-bordered thead th {
    font-weight:600;
	text-transform:uppercase;
}
.thankyou-promotion{
	padding:70px 0px 70px;
}
.thankyou-promoton-content-wrapper{
	max-width:290px;
	margin-left:auto;
	margin-right:auto;
}
.thankyou-promoton-content-wrapper h1{
	font-size:24px;
	text-transform:uppercase;
	font-weight:700;
	margin-bottom: 32px;
}
.thankyou-promoton-content-wrapper img{
	margin-bottom:25px;
}
.thankyou-promoton-content-wrapper p{
	font-size:16px;
	color:#595959;
	font-weight:400;
	margin-bottom:40px;
}
.thankyou-promoton-content-wrapper table{
	width:100%;
	margin-bottom: 50px;
}
.thankyou-promoton-content-wrapper table tr td{
	font-size:16px;
	color:#595959;
	font-weight:400;
	line-height:30px;
	text-align:left;
}
.thankyou-promoton-content-wrapper table tr td:nth-child(even){
	text-align:right;
	font-weight:600;
}
.thankyou-promotion a.common-button{
	border-radius:0px;
	text-transform:uppercase;
}
.thankyou-promotion a.common-button:hover{
	background: rgb(30, 167, 255);
	background: -moz-linear-gradient(90deg, rgba(30, 167, 255, 1) 0%, rgba(22, 112, 168, 1) 100%);
	background: -webkit-linear-gradient(90deg, rgba(30, 167, 255, 1) 0%, rgba(22, 112, 168, 1) 100%);
	background: linear-gradient(90deg, rgba(30, 167, 255, 1) 0%, rgba(22, 112, 168, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1ea7ff", endColorstr="#1670a8", GradientType=1);
	color: #000;
}
.upload-documents-area{
	padding:30px;
}
.upload-documents-area p{
	font-size:16px;
	color:#595959;
	font-weight:400;
}
.upload-documents-wrapper h6{
	text-decoration:underline;
	display:inline-block;
}
.upload-documents-wrapper a{
	font-size: 16px;
    color: #2087c7;
    font-weight: 700;
    text-transform: uppercase;
	margin-left:20px;
}
.tab-from{
	display:table;
	margin:0px;
	width:100%;
}
.tab-from > div{
	display:table-cell;
	/*padding-right:15px;*/
	vertical-align:middle;
}
.tab-from > div:last-child{
	width:65%;
}
.tab-from > div label{
	margin:0px;
}
.product-filter-area{
	padding:0px 30px 15px;
	border-bottom: 1px solid #dee2e6;
}
.product-filter-button-area{
	/*text-align:right;*/
}
.product-filter-button-area a{
	border: 1px solid transparent !important;
	padding-left:35px !important;
	padding-right:35px !important;
	border-radius:0px;
	text-transform:uppercase;
}
.product-filter-button-area a:hover{
	border: 1px solid #2087c7 !important;
}
.your-product-listing table{
	width:100%;
}
.your-product-listing table tr{
	border-bottom: 1px solid #dee2e6;
}
.your-product-listing table tr th{
	font-weight:400;
	text-transform:uppercase;
	padding:10px 25px;
}
.your-product-listing table tr td{
	padding:10px 25px;
	vertical-align:top;
}
.your-product-listing table tr td:first-child{
	width:100px;
}
.your-product-image{
	height:100px;
	width:100px;
	object-fit:cover;
	border: 2px solid #dee2e6;
}
.action-area a{
	color:#2087c7;
	text-transform:uppercase;
	font-weight:bold;
}
.action-area{
	text-align:center;
}
.action-area a:first-child{
	margin-bottom:5px;
	display:inline-block;
}
.action-area a:hover{
	text-decoration:underline;	
}
.h-code-mobile{
	margin-top:10px;
}
.banner-promotion-area{
	padding-bottom:50px;
}
.hs-code-list{
	margin:0px;
	padding:0px;
	list-style:none;
	display:flex;
	gap:30px;
	flex-wrap:wrap;
}
textarea.form-style{
	line-height:normal;
	padding:15px;
}
.mobile-seller label {
    font-size: 16px;
    color: #282828;
    font-weight: 700;
    display: block;
    margin-bottom: 14px;
}
.buyer-dashboard .logo-and-info h2 {
    margin-top: 18px;
    margin-bottom: 6px;
}
.buyer-dashboard .logo-and-info {
    padding: 30px 30px 10px;
}
.buyers-form-wrapper{
	max-width:515px;
}
.buyer-dashboard hr {
   width: 100vw;
    margin-left: -50vw;
    left: 50%;
    position: relative;
}
.nav-pills.inquiry .nav-link{
	color: #000;
	border-radius:0px;
	border: 1px solid #ececec;
}
.nav-pills.inquiry .nav-link.active{
    background-color: #ececec;
}
.nav-pills.inquiry .nav-link h3, .card-header h3{
	font-size: 16px;
    font-weight: 700;
	margin-bottom:10px;
}
.nav-pills.inquiry .nav-link p, .card-header p{
	font-size:14px;
	margin-bottom: 10px;
}
.nav-pills.inquiry .nav-link p:last-child, .card-header p:last-child{
	margin:0px;
}
.nav-pills.inquiry .nav-link {
    color: #000;
    border-radius: 0px;
    border: 1px solid #ececec;
    padding: 15px 25px;
}
.queries-content .nav-pills.inquiry .nav-link {
    padding: 15px 25px;
}
.queries-content .tab-content{
	padding:30px 30px 30px 0px;
}
.inquirys{
	height:100%;
	border-right:1px solid #dddddd;
}
.queries-content .card-header{
	position:relative;
}
.queries-content .card-header h5{
	position:absolute;
	top:0px;
	left:0px;;
	width:100%;
	height:100%;
	opacity:0;
	z-index: 1;
}
.thankyou-feedback-area {
	padding:120px 0px;
}
.thankyou-feedback-area .buyer-signup-wrapper h5{
	font-size:16px;
	font-weight:500;
	color:#595959;
	margin-bottom:15px;
}
.thankyou-feedback-area .buyer-signup-wrapper p{
	color:#595959;
	font-weight:400;
	margin-bottom:50px;
}
.chat-search-wrapper{
	padding:10px 15px;
	border-bottom:1px solid #d3d3d3;
}
.chat-search-area input{
	width:100%;
	border:0px;
	border-radius:0px;
	padding: 10px 15px;
    font-size: 16px;
    color: #000;
	background: white;
}
.chat-search-area input:focus{
	outline:0;
}
.chat-search-area{
	border:1px solid #d3d3d3;
	padding-right:15px;
	margin-bottom: 0px !important;
}
.chat-search-area button{
	background:transparent;
	border:0px;
	border-radius:0px;
}
.chat-search-area button img{
	width:15px;
}
.each-chathistorybox h4{
	font-size:14px;
	color:#000;
	font-weight:700;
	margin-bottom: 20px;
}
.each-chathistorybox h4 span{
	margin-left:10px;
}
.each-chathistorybox p{
	font-size:12px !important;
	color:#595959;
	margin-bottom:0px !important;
}
.chat-history{
	/*padding:15px 25px;*/
	max-height: 64vh;
    overflow-y: scroll;
}
.chat-date-and-time{
	position: absolute;
    top: -5px;
    right: -10px;
    text-align: right;
}
.chat-date-and-time p{
	font-size:10px !important;
	color:#7a7a7a;
	margin-bottom:2px !important;
}
.queries-content .chat-history .nav-pills.inquiry .nav-link {
    padding: 5px 10px;
    /* padding: 20px 25px; */
}
.chat-preffered-timming{
	padding:22px 30px;
	border-bottom: 1px solid #d3d3d3;
}
.chat-preffered-timming p{
	font-size:14px;
	color:#000;
	margin:0px;
}
.tab-content .msger {
    position: relative;
    display: flex;
    right: auto;
    width: 100%;
    max-width: 100%;
    box-shadow: none;
    height: 100%;
	max-height: none;
	min-height:660px;
	bottom:auto;
	border:0px;
}
.tab-content .msger .msger-header{
	display:none !important;
}
.tab-content .msger-chat {
    background-color: #fff;
}
.tab-content .msg-bubble {
    background: #bbdcf1
}
.card{
	border-radius:0px !important;
}
.product-listing-pagination ul{
	margin:0px;
	padding:0px;
	list-style:none;
	display:flex;
	margin: 0px;
    padding: 0px;
    list-style: none;
    display: flex;
    justify-content: end;
    gap: 15px;
}
.product-listing-pagination ul li{
	font-weight:400;
	font-size:16px;
}
.product-listing-pagination ul li a{
	color:inherit;
	width:32px;
	height:32px;
	display:flex;
	align-items:center;
	justify-content:center;
	border: 1px solid #d3d3d3;
}
.product-listing-pagination ul li.active a{
	background-color:#1a83c6;
	color:#fff;
}
.product-listing-wrapper{
	padding-bottom:30px;
}
.product-listing-pagination{
	margin-top:30px;
}
.short-area {
	padding-bottom:10px !important;
}
.short-area ul{
	margin:0px;
	padding:0px;
	list-style:none;
}
.short-area ul li{
	margin-bottom:10px;
}
.short-area ul li a{
	color:#fff;
	font-size:14px;
	font-weight:500;
}
.all-category-listing-wrapper{
	padding:50px 0px;
}
.all-category-listing-wrapper .all-category-listing{
	flex-wrap:wrap;
	justify-content:center;
}
.all-category-listing-wrapper .all-category-listing .each-category{
	width:calc(20% - 15px);
	margin-bottom:30px;
}
.all-seller-wrapper{
	padding:50px 0px;
}
.each-seller-icon{
	text-align:center;
	margin-bottom:30px;
	position:relative;
	overflow: hidden;
}
.each-seller-icon img{
	margin-bottom:20px;
}
.all-seller-wrapper .sellers-result {
    margin-top: 50px;
}
/*---tanmoy----*/
.nav-pills.inquiry .nav-link{
	color: #000;
	border-radius:0px;
	border: 1px solid #ececec;
}
.nav-pills.inquiry .nav-link.active{
    background-color: #ececec;
	margin: 5px;
}
.nav-pills.inquiry .nav-link h3{
	font-size:18px
}
.nav-pills.inquiry .nav-link p{
	font-size:14px
}
.prefill .input-group-prepend span{
	margin-right:0px !important;
}
.input-group-text {   
    padding: 0.375rem 17px;    
    line-height: 36px;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #b0b0b0;
    border-radius: 0;
}
.form-control {    
    border: 1px solid #b0b0b0;
    border-radius: 0px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    height: 50px;
}
/***************************************
********** EDIT BY SOUVICK PANJA *********
***************************************/
/************ Start:: kirloskar brothers banner section *****************/
.kirloskar-brothers-banner-section{
	padding-top:195px;
	padding-bottom:0px;
	position:relative;
}
/*.kirloskar-brothers-banner-section::after{
	content:url('../images/basic-info-logo-edit.png');
	position:absolute;
	bottom:-45px;
	left:109px;
}*/
/*.kirloskar-brothers-banner-heading{
	display:inline-block;
	position:relative;
	margin-left:300px;
	margin-top:20px;
}
.kirloskar-brothers-banner-heading::after{
	content:'';
	background-color:#00ed70;
	width:17px;
	height:17px;
	position:absolute;
	top:0px;
	right:-27px;
	border-radius:50%;
	border:1px solid #fff;
}*/
.kirloskar-brothers-banner-section h1{
	font-family: 'LEMON MILK Bold';
	color:#fff;
	font-weight:700;
	font-size:31px;
	text-transform:uppercase;
}
.kirloskar-brothers-banner-heading img{
	margin-right:50px;
	width: 150px;
    height: 150px;
    border-radius: 50%;
}
.kirloskar-brothers-banner-heading{
	position:relative;
	top:40px;
}
/************ End:: kirloskar brothers banner section *****************/
/************ Start:: about us Section *****************/
.about-us-section{
	padding-top:80px;
	padding-bottom:80px;
}
.about-us-wrapper .common-button{
	border-radius:0;
	border:none;
	text-transform:uppercase;
	padding:15px 30px;
}
.about-us-section h2{
	font-family: 'LEMON MILK Bold';
	color:#282828;
	font-size:31px;
	font-weight:700;
	margin-bottom:20px;
}
.about-us-section p{
	color:#595959;
	font-size:16px;
	font-weight:400;
	font-family: 'Poppins', sans-serif;
	line-height:30px;
	margin-bottom:20px;
}
.about-us-section .about-us-text{
	padding-left:30px;
}
.about-us-text button img{
	margin-right:10px;
}
/************ End:: about us Section *****************/
/************ Start:: trending products Section *****************/
.trending-products-section{
	padding-top:40px;
	padding-bottom:50px;
}
.trending-products-section .each-selling-product-image{
	border: 1px solid #d6d6d6;
}
.trending-products-section .each-selling-product-content p {
    font-size: 12px;
	max-width:230px;
	margin:0 auto;
}
/************ End:: trending products Section *****************/
/************ Start:: most inquired products Section *****************/
.most-inquired-products{
	padding:40px 0;
}
.most-inquired-products .each-selling-product{
	background-color:#fff;
}
.most-inquired-products .each-selling-product-content h6 {
	min-height:50px;
}
/*
.featured-bar{
	font-family: 'Poppins', sans-serif;
	color:#fff;
	font-size:10px;
	font-weight:400;
	text-transform:uppercase;
	background-color:#1a83c6;
	padding: 50px 30px 5px 30px;
	position:absolute;
	top: -29px;
    right: -46px;
    transform: rotate(45deg);
}*/
/************ End:: most inquired products Section *****************/
.your-verticals label{
	padding-left:20px;
	padding-right:78px;
}
.your-verticals .each-form-field-box{
	margin-top:25px;
}
.your-verticals button{
	text-transform:uppercase;
}
.verticals-modal .modal-dialog {
    max-width: 715px;
	border-radius: 25px;
    overflow: hidden;
}
.verticals-modal .modal-content{
	border-radius: 25px;
	overflow: hidden;
}
.verticals-modal ul li{
	list-style:none;
	color:#c2c2c2;
	font-size:16px;
	padding:18px 0;
}
.verticals-modal ul{
	padding:0;
	margin:0;
}
.verticals-modal .modal-header{
	border-bottom:0px;
	padding:30px 0 25px 0;
	background-color:#2087c7;
}
.verticals-modal .modal-body{
	padding:0;
}
.verticals-modal .modal-wrapper{
	padding:0 50px;
}
.verticals-modal h4{
	font-size:16px;
	color:#fff;
	font-weight:700;
	text-transform:capitalize;
}
.verticals-modal .each-verticals-list{
	border-bottom:1px solid #c2c2c2;
}
.verticals-modal .total-cost {
	background-color:#2087c7;
	border:none;
}
.total-cost ul li{
	color:#fff;
	font-weight:700;
}
.verticals-modal .common-button{
	border-radius:0;
	margin:32px 0 25px;
	border:0px;
}
.verticals-modal .each-verticals-list:hover{
	background-color:#ccebff;
	color:#595959;
}
.each-verticals-list.active{
	background-color:#ccebff;
}
.verticals-modal .each-verticals-list.active ul li {
    color: #000;
}
/************ Start:: charges Section *****************/
.charges-area-heading h2{
	font-family: 'LEMON MILK Bold';
	font-weight:700;
	color:#282828;
	text-align:center;
	font-size:31px;
	margin-bottom:40px;
}
.charges-area-wrapper{
	max-width:700px;
	margin:0 auto;
	padding-top:45px;
	padding-bottom:32px;
}
.charges-area .each-pricing-info{
	padding:15px 0;
}
.charges-area .common-button{
	border-radius:0;
	padding:15px 80px;
	text-align:center;
	margin-top:25px;
}
.charges-area .pay-btn{
	margin:0 auto;
}
.charges-type h3{
	font-family: 'Poppins', sans-serif;
	font-weight:700;
	font-size:16px;
	margin:0;
}
/************ End:: charges Section *****************/
.star-rating {
  display:flex;
  flex-direction: row-reverse;  
  justify-content:space-around;
  padding:0 .2em;
  text-align:center;
  width:50%;;
}

.star-rating input {
  display:none;
}

.star-rating label {
  color:#e3e3e3;
  cursor:pointer;
  font-size:50px;
}

.star-rating :checked ~ label {
  color:#ffc107;
}

.star-rating label:hover,
.star-rating label:hover ~ label {
  color:#ffc107;
}
.notification-dropdown {
	padding:15px 15px 0px;
	min-width: 275px;
	background:#f3f3f3;
}
.notification-thumb{
	min-width:40px;
	height:40px;
	border-radius:5px;
	overflow:hidden;
}
.notification-dropdown p{
	font-size:12px;
	color:#000;
	margin:0px;
}
.each-notification{
	gap:15px;
}
.notification-dropdown ul li{
	padding-bottom:15px;
	margin-bottom:15px;
	border-bottom:1px solid #c9c9c9;
}
.notification-dropdown ul li:last-child{
	padding-bottom:0px;
	margin-bottom:0px;
	border-bottom:0px solid #c9c9c9;
}
.corner-sticky{
	position: absolute;
    top: -13px;
    left: -45px;
    background: #dd9c00;
    transform: rotate(-45deg);
    width: 116px;
    height: 55px;
    display: flex;
    align-items: end;
    justify-content: center;
    padding-bottom: 7px;

}
.custom_color_featured{
	background: #1a83c6;
}
.corner-sticky p{
	margin:0px;
	color:#fff;
	font-size: 10px;
	text-transform:uppercase;
}
.each-selling-product, .each-featured-product{
	cursor: pointer;
}
.pt-50{
	padding-top:50px;
}
.text_more{
	padding: 5px;
}
.pb-80{
	padding-bottom:80px;
}
.each-selling-product-content  h6{
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}
.product-list-page-banner{
	background-size:cover !important;
	background-position: 79% !important;
	min-height:425px !important;

}
.custom-file-label{
	border: 1px solid #b0b0b0 !important;
    border-radius: 0px !important;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;
    height: 50px !important;
}
.custom-file-label::after {
    height: 100% !important;
    display: flex !important;
    align-items: center !important;
}
.new-banner-slider-area .swiper-pagination{
    width:100%;
	margin-top:20px;
  }
  .new-banner-slider-area .swiper-pagination .swiper-pagination-bullet{
    width:12px;
    height:12px;
    margin:0px 5px;
  }
  .new-banner-slider-area .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active{
	background-color: #166fa7ed;
  }
  .new-banner-area img{
	width:100%;
	height:490px;
	object-fit:cover;
	object-position:top;

  }
  .each-chathistorybox .seller_table, .each-chathistorybox .buyer_table{
	display:none;
  }
  .each-chathistorybox .seller_table_name, .each-chathistorybox .buyer_table_name{
    margin-top: 0px;
    margin-bottom: 5px;
  }
  .each-chathistorybox .seller_table_name a, .each-chathistorybox .buyer_table_name a{
	color: #282828 !important;
	font-size: 16px;
    font-family: 'LEMON MILK Bold';
	font-weight:normal !important
  }
  .chat-date-and-time-area{
	font-family: 'Poppins', sans-serif;
  }
  .each-chathistorybox .last-message{
	font-family: 'Poppins', sans-serif;
	margin-bottom: 6px !important;
  }
  .welcome_area h4{
	font-family: 'Poppins', sans-serif;
  }
  .welcome_area small{
	font-family: 'Poppins', sans-serif;
  }
  .chat-preffered-timming a{
	font-family: 'Poppins', sans-serif;
	color:#000;
  }
  .each-chathistorybox a.chat-date-and-time-area span{
	color:#000
  }
  .new-container{
	padding:50px 100px
  }
  .buyer-profile-content-wrapper h3{
	padding: 15px 25px;
    margin-bottom: 0px;
    border-bottom: 1px solid #e5e5e5;
    font-family: 'LEMON MILK Bold';
    font-size: 18px;
  }
  .buyer-profile-content-wrapper main{
	padding: 15px 25px;
  }
  .buyer-profile-content-wrapper main p{
	font-family: 'Poppins', sans-serif;
  }
  .buyer-profile-content-wrapper h6 {
    margin-bottom: 15px;
}
.buyer-profile-chat-wrapper{
	background:#dddddd;
	padding: 60px 20px 10px;
}
.chat-date span{ 
	font-size: 12px;
    padding: 3px 24px;
    background-color: #fff;
    color: #000;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
}
.chat-date{
	position: absolute;
	width:100%;
	z-index: 1;;
	margin:15px 0px
}
.chat-date:after{
	width: 100%;
    height: 1px;
    background: #fff;
    top: 12px;
    content: '';
    position: absolute;
    left: 0px;
    z-index: -1;
}
.image-for-related-product-chat {
	background-color: #f7f7f7;
	padding: 10px;
	border-radius: 5px;
	gap:20px;
	align-items: center;
}
.text_more_product_chat1{
	font-family: 'Poppins', sans-serif;
	/* font-size:16px; */
	font-size:12px;
	font-weight: 500;
	/* color:#000; */
	color: #7c7c7c;
}
.buyer-profile-content-wrapper .msger-chat{
	background-color: #dddddd;
}

.msg-text video {
	width: 100% !important;
}

@media(max-width:1500px){
	.new-container {
		padding: 50px 50px;
	}
}
@media(max-width:1025px){
	.new-container {
		padding: 30px 30px;
	}
}
@media(max-width:767px){
	.buyer-profile-content-wrapper main {
		padding: 15px 15px;
	}
	.image-for-related-product-chat{
		display:block !important
	}
}