:root {
  --body-bg: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  --msger-bg: #fff;
  --border: 2px solid #ddd;
  --left-msg-bg: #ececec;
  --right-msg-bg: #579ffb;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  /* box-sizing: inherit; */
}
.msger {
	display: flex;
	flex-flow: column wrap;
	justify-content: space-between;
	width: 100%;
	max-width: 428px;
	/* margin: 25px 10px; */
	height: calc(100% - 50px);
	max-height:550px;
	border: var(--border);
	background: #f6f6f6;
	box-shadow: 0 15px 15px -5px rgba(0, 0, 0, 0.2);
	position: fixed;
	bottom: 0px;
	right: 50px;
	z-index: 9999;
	box-shadow: 0px 4px 9px 0px rgba(0,0,0,0.25);
	display:none;
}
.msger.active{
	display:flex;
}
.msger-header {
	display: flex;
	justify-content: space-between;
	padding: 10px 22px;
	border-bottom: var(--border);
	/*background: #eee;*/
	background: rgb(30, 167, 255);
	background: -moz-linear-gradient(90deg, rgba(30, 167, 255, 1) 0%, rgba(22, 112, 168, 1) 100%);
	background: -webkit-linear-gradient(90deg, rgba(30, 167, 255, 1) 0%, rgba(22, 112, 168, 1) 100%);
	background: linear-gradient(90deg, rgba(30, 167, 255, 1) 0%, rgba(22, 112, 168, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1ea7ff", endColorstr="#1670a8", GradientType=1);
	border:1px solid #d4d4d4;
}
.msger-header-title h4{
	text-transform:uppercase;
	color:#fff;
	font-size:16px;
	font-weight:600;
	margin-bottom:0px;
}
.status-checking{
	margin:0px;
	color:#fff;
	font-size:14px;
}
.status-checking img{
	margin-right:7px;
}
.msger-header-options ul{
	margin:0px;
	padding:0px;
	list-style:none;
	gap:25px;
	display:flex;
}
.chat-timming-area{
	position:absolute;
	top:calc(100% + 1px);
	background:#fff;
	left:50%;
	transform:translateX(-50%);
	box-shadow: 0px 4px 9px 0px rgba(0,0,0,0.25);
	border-bottom-left-radius:10px;
	border-bottom-right-radius:10px;
	overflow:hidden;
}
.chat-timming-area p{
	margin:0px;
	font-size:14px;
	padding:3px 20px 3px 30px;
	color:#666666;
	white-space: nowrap;
}
.chat-timming-area p img{
	margin-left:13px;
}
.msg-text{
	font-family: 'Poppins', sans-serif;
	font-size:14px;
	color:#000;
}
.msger-chat {
  flex: 1;
  overflow-y: auto;
  padding: 30px;
}
.msger-chat::-webkit-scrollbar {
  width: 6px;
}
.msger-chat::-webkit-scrollbar-track {
  background: #ddd;
}
.msger-chat::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}
.msg {
  display: flex;
  align-items: flex-end;
  margin-bottom: 15px;
}
.msg:last-of-type {
  margin: 0;
}
.msg-img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  background: #ddd;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
}
.msg-bubble {
  max-width: 335px;
  padding: 10px 10px 5px;
  border-radius: 5px;
  background:#fff;
  /*min-height:75px;*/
}
.msg-info {
  	display: flex;
  	justify-content: flex-end;
  	align-items: center;
	right: 10px;
    bottom: 2px;
}
.msg-info-name {
  margin-right: 10px;
  font-weight: bold;
}
.msg-info-time {
  font-size: 9px;
}

.left-msg .msg-bubble {
  border-bottom-left-radius: 0;
}

.right-msg {
  flex-direction: row-reverse;
}
.right-msg .msg-bubble {
  background: #2086c7;
  color: #fff;
  border-bottom-right-radius: 0;
}
.right-msg .msg-img {
  margin: 0 0 0 10px;
}
.right-msg .msg-bubble .msg-text{
    color: #fff;
}
.msger-inputarea {
  display: flex;
  padding: 10px;
  border-top: 1px solid #d6d6d6;
  background: #f6f6f6;
}
.msger-inputarea * {
  padding: 6px;
  border: none;
  border-radius: 3px;
  font-size: 1em;
  background:transparent;
}
.msger-input {
  flex: 1;
  background: #fff;
  border:1px solid #d4d4d4;
  border-radius:0px;
  font-size:16px;
}
.msger-send-btn {
  margin-left: 10px;
  background: rgb(0, 196, 65);
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.23s;
}
.msger-send-btn:hover {
  background: rgb(0, 180, 50);
}

.msger-chat {
  background-color: #f6f6f6;
}
:focus{
	outline:0px !important
}
.close-convertion-popup{
	position:absolute;
	background:#f6f6f6;
	top:0px;
	left:0px;
	width:100%;
	height:100%;
	display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
	display:none;
	z-index: 99;
}
.close-convertion-popup.active{
	display:flex;
}

.close-convertion-popup p{
	font-size:16px;
	margin-bottom:30px;
	line-height: 30px;
	text-align:center;
	font-weight:400;
}
.close-convertation-button{
	display:flex;
	gap:15px;
}
.close-convertation-button a{
	border-radius:0px;
	min-width:148px;
}
.close-convertation-button a.simple-border-button{
	border:2px solid #85bbdd;
	background:transparent;
}
.close-convertation-button a.gradient-button:hover{
	background: rgb(30, 167, 255);
	background: -moz-linear-gradient(90deg, rgba(30, 167, 255, 1) 0%, rgba(22, 112, 168, 1) 100%);
	background: -webkit-linear-gradient(90deg, rgba(30, 167, 255, 1) 0%, rgba(22, 112, 168, 1) 100%);
	background: linear-gradient(90deg, rgba(30, 167, 255, 1) 0%, rgba(22, 112, 168, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1ea7ff", endColorstr="#1670a8", GradientType=1);
	color:#fff;
}
.close-convertation-button a.simple-border-button:hover{
	border:2px solid #85bbdd;
	background:transparent;
	color:#000;
}
.close-convertion-popup.feedback-popup{
	display:none;
}
.close-convertion-popup.feedback-popup.active{
	display:flex;
}
.inactive-message-send-area{
	background:#f6f6f6;
	border:1px solid #d9d9d9;
	padding:10px;
}
.inactive-message-send-area p{
	color:#000;
	font-size:16px;
	text-align:center;
	margin:0px;
}
.msger-inputarea.hide{
	display:none;
}
.inactive-message-send-area{
	display:none;
}
.inactive-message-send-area.active{
	display:block;
}
.chat_file_download{
	position: relative;
	/*right: 20px;*/
}
.file-icon img{
	width:30px;
	margin-right:10px;
}