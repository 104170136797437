@font-face {
    font-family: 'LEMON MILK Bold';
    src: url('../fonts/LEMONMILK-Bold.eot');
    src: url('../fonts/LEMONMILK-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/LEMONMILK-Bold.woff2') format('woff2'),
        url('../fonts/LEMONMILK-Bold.woff') format('woff'),
        url('../fonts/LEMONMILK-Bold.ttf') format('truetype'),
        url('../fonts/LEMONMILK-Bold.svg#LEMONMILK-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'LEMON MILK Rg';
    src: url('../fonts/LEMONMILK-Regular.eot');
    src: url('../fonts/LEMONMILK-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/LEMONMILK-Regular.woff2') format('woff2'),
        url('../fonts/LEMONMILK-Regular.woff') format('woff'),
        url('../fonts/LEMONMILK-Regular.ttf') format('truetype'),
        url('../fonts/LEMONMILK-Regular.svg#LEMONMILK-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}